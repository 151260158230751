import LockOutlined from '@mui/icons-material/LockOutlined';
import { Alert, Grid, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import {
    GET_SSN_TASK_CONTENT_ACTION,
    getSsnTaskContent,
} from 'actions/taskFlows/getSsnTaskContent';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { EntityType, Tasks } from 'api/generated/enums';
import {
    HouseholdMemberInfoDto,
    IHouseholdMembersDto,
    ISocialSecurityNumbersDto,
    ISsnDto,
    SsnDto,
    TaskDto,
} from 'api/generated/models';
import Form from 'components/Form';
import Skeleton from 'components/Skeleton';
import { RH_TEAM_CARE_EMAIL } from 'constants/teams';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ICHRA_LAUNCH_FLOW_INDEX } from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskList';
import {
    SLIM_MAX_WIDTH,
    getPreviousTask,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import SocialSecurityTaskField from 'pages/dashboard/ichraTaskFlow/taskFlowPages/socialSecurityNumbersPage/SocialSecurityTaskField';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasCompletedRequests } from 'selectors/activity';
import { getDisplayFirstName } from 'utilities/household';
import { hasValue, invalidSsn } from 'utilities/index';
import { StringSchema, object, string } from 'yup';

const ZERO = 0;
export const SSN_INDEX_BEFORE_FIRST_DASH = 3;
export const SSN_INDEX_BEFORE_SECOND_DASH = 5;

type ISocialSecurityNumberPage = {
    currentTask: TaskDto;
};
type ISsnCollectionInfo = ISsnDto & {
    isReadonly: boolean;
};

const removeFormat = (ssn: string) => ssn.replace(/-/g, '');
const addFormat = (ssn: string) => {
    const unformattedSsn = removeFormat(ssn);
    return `${unformattedSsn.substring(
        ZERO,
        SSN_INDEX_BEFORE_FIRST_DASH
    )}-${unformattedSsn.substring(
        SSN_INDEX_BEFORE_FIRST_DASH,
        SSN_INDEX_BEFORE_SECOND_DASH
    )}-${unformattedSsn.substring(SSN_INDEX_BEFORE_SECOND_DASH)}`;
};

const SocialSecurityNumberPage = ({ currentTask }: ISocialSecurityNumberPage) => {
    const dispatch = useThunkDispatch();
    const { hasCompletedAllRequests, hhmsFromState, ssnsFromChunk, userFlows } = useSelector(
        (state: AppStore) => ({
            hasCompletedAllRequests: hasCompletedRequests(
                state,
                GET_HOUSEHOLD_ACTION,
                LIST_VISIBLE_FLOWS_ACTION,
                GET_SSN_TASK_CONTENT_ACTION
            ),
            hhmsFromState: state.householdMembers,
            ssnsFromChunk: state.socialSecurityNumbers,
            userFlows: state.userFlows,
        })
    );
    const { userId, user, memberVerifiedInfo } = useUserProps();
    const ichraTasks = userFlows[ICHRA_LAUNCH_FLOW_INDEX]?.tasks;
    const hhmTask = getPreviousTask(
        ichraTasks as TaskDto[],
        Tasks.HouseholdMembers,
        Tasks.EnterHouseholdInfo
    );
    const hhmsFromChunk:
        | HouseholdMemberInfoDto[]
        | undefined = (hhmTask?.response as IHouseholdMembersDto)?.householdMembers;

    const [hasMappedSsns, setHasMappedSsns] = useState(false);
    const [socialSecurityNumbers, setSocialSecurityNumbers] = useState(
        {} as Record<string, ISsnCollectionInfo>
    );

    const schema = useMemo(() => {
        const objectShape: Record<string, StringSchema> = {};
        const isValidSsn = (value: string | undefined) => !invalidSsn(addFormat(value ?? ''));
        objectShape[userId] = string().test(
            'primaryValidSsn',
            `${getDisplayFirstName(user)}'s SSN is invalid`,
            isValidSsn
        );
        hhmsFromChunk?.forEach((hhm) => {
            objectShape[hhm.householdMemberId as string] = string().test(
                'memberValidSsn',
                `${getDisplayFirstName(hhm)}'s SSN is invalid`,
                isValidSsn
            );
        });
        return object(objectShape);
    }, [hhmsFromChunk, user, userId]);

    const { validate, errors } = useForm(schema);

    const onSubmit = useCallback(async () => {
        const ssnMap: Record<string, string> = {};
        Object.entries(socialSecurityNumbers)?.forEach(
            (ssnInfo) => (ssnMap[ssnInfo[0] ?? ''] = ssnInfo[1]?.socialSecurityNumber ?? '')
        );
        const { isValid } = await validate(ssnMap);
        if (isValid) {
            const chunk: ISocialSecurityNumbersDto = { socialSecurityNumbers: [] };
            Object.entries(socialSecurityNumbers).forEach((x) =>
                chunk.socialSecurityNumbers?.push({
                    entityKeyId: x[0],
                    entityType: x[0] === userId ? EntityType.User : EntityType.HouseholdMember,
                    socialSecurityNumber: addFormat(x[1].socialSecurityNumber ?? ''),
                } as SsnDto)
            );
            await dispatch(completeTask(currentTask.globalId, chunk));
        }
    }, [currentTask.globalId, dispatch, socialSecurityNumbers, userId, validate]);

    const onSsnChange = (ssn: string, entityKeyId: string) => {
        const updatedSsns = { ...socialSecurityNumbers };
        (updatedSsns[entityKeyId] as ISsnCollectionInfo).socialSecurityNumber = ssn;
        setSocialSecurityNumbers(updatedSsns);
    };

    useEffect(() => {
        if (hasValue(currentTask?.globalId) && currentTask.taskId === Tasks.SocialSecurityNumbers) {
            dispatch(getSsnTaskContent(currentTask?.globalId ?? ''));
        }
    }, [currentTask?.globalId, currentTask.taskId, dispatch]);

    useEffect(() => {
        if (hasCompletedAllRequests) {
            const existingSsns: Record<string, ISsnCollectionInfo> = {};
            const primarySsnInfo: ISsnCollectionInfo = {
                entityKeyId: userId,
                entityType: EntityType.User,
                isReadonly: false,
                socialSecurityNumber: '',
            };
            const existingPrimarySsnFromMvi = memberVerifiedInfo?.socialSecurityNumber;
            const existingPrimarySsnFromChunk = ssnsFromChunk?.socialSecurityNumbers?.find(
                (x) => x.entityKeyId === userId
            )?.socialSecurityNumber;
            primarySsnInfo.socialSecurityNumber = removeFormat(
                existingPrimarySsnFromMvi ?? existingPrimarySsnFromChunk ?? ''
            );
            primarySsnInfo.isReadonly = hasValue(existingPrimarySsnFromMvi);
            existingSsns[userId] = primarySsnInfo;
            hhmsFromChunk?.forEach((hhm) => {
                const hhmSsnInfo: ISsnCollectionInfo = {
                    entityKeyId: hhm.householdMemberId ?? '',
                    entityType: EntityType.HouseholdMember,
                    isReadonly: false,
                    socialSecurityNumber: '',
                };
                const existingHhmSsnFromState = hhmsFromState?.find(
                    (hhmFromState) => hhmFromState.householdMemberId === hhm.householdMemberId
                )?.socialSecurityNumber;
                const existingHhmSsnFromChunk = ssnsFromChunk?.socialSecurityNumbers?.find(
                    (x) => x.entityKeyId === hhm.householdMemberId
                )?.socialSecurityNumber;
                const existingHhmSsn = removeFormat(
                    existingHhmSsnFromState ?? existingHhmSsnFromChunk ?? ''
                );
                hhmSsnInfo.socialSecurityNumber = existingHhmSsn;
                hhmSsnInfo.isReadonly = hasValue(existingHhmSsnFromState);
                existingSsns[hhm.householdMemberId ?? ''] = hhmSsnInfo;
            });
            setSocialSecurityNumbers(existingSsns);
            setHasMappedSsns(true);
        }
    }, [
        hasCompletedAllRequests,
        hhmsFromState,
        hhmsFromChunk,
        memberVerifiedInfo?.socialSecurityNumber,
        ssnsFromChunk,
        userId,
    ]);

    return (
        <Grid container direction="column" gap={3} maxWidth={SLIM_MAX_WIDTH} width="100%">
            <IchraFlowTitle title="Enter Social Security Numbers" />
            <Grid item justifyContent="center">
                <Alert icon={<LockOutlined />} severity="info">
                    We use 256-bit AES encryption to secure social security numbers and store them
                    as one-way 256-bit SHA hashes.
                </Alert>
            </Grid>
            <Grid item justifyContent="center">
                <Typography color="GrayText">
                    If you need to change a previously collected social security number, please
                    reach out to <a href={`mailto:${RH_TEAM_CARE_EMAIL}`}>{RH_TEAM_CARE_EMAIL}</a>.
                </Typography>
            </Grid>
            <Form isLoading={!hasMappedSsns}>
                <Skeleton
                    count={3}
                    height={55}
                    isEnabled={!hasMappedSsns}
                    rowProps={ichraFlowSkeletonRowProps}
                    sx={{ mb: 4 }}
                >
                    <Grid container justifyContent="center" spacing={4}>
                        <SocialSecurityTaskField
                            entityKeyId={userId}
                            errors={errors?.[userId]}
                            firstName={user?.firstName ?? ''}
                            isReadonly={socialSecurityNumbers[userId]?.isReadonly ?? false}
                            socialSecurityNumber={
                                socialSecurityNumbers[userId]?.socialSecurityNumber ?? ''
                            }
                            updateSocialSecurityNumber={onSsnChange}
                        />
                        {hhmsFromChunk?.map((hhm) => (
                            <React.Fragment key={hhm.householdMemberId}>
                                <SocialSecurityTaskField
                                    entityKeyId={hhm.householdMemberId ?? ''}
                                    errors={errors?.[hhm?.householdMemberId as string]}
                                    firstName={hhm?.firstName ?? ''}
                                    isReadonly={
                                        socialSecurityNumbers[hhm?.householdMemberId ?? '']
                                            ?.isReadonly ?? false
                                    }
                                    socialSecurityNumber={
                                        socialSecurityNumbers[hhm.householdMemberId ?? '']
                                            ?.socialSecurityNumber ?? ''
                                    }
                                    updateSocialSecurityNumber={onSsnChange}
                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                </Skeleton>
            </Form>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    handleNext={onSubmit}
                    isLoading={!hasMappedSsns}
                    showPreviousButton
                />
            </Grid>
        </Grid>
    );
};

export default hot(module)(SocialSecurityNumberPage);
