import { getTeamPathwayBlueprints } from 'actions/pathwayBlueprint/getTeamPathwayBlueprints';
import {
    TRANSITION_TO_RENEWING_ACTION,
    transitionToRenewing,
} from 'actions/team/transitionToRenewing';
import { ViewPathwayBlueprints } from 'api/generated/permissions';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import TextField from 'components/TextField';
import UnselectableText from 'components/UnselectableText';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import moment from 'moment';
import HandoffModal, { IRequirement } from 'pages/dashboard/HandoffModal';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';

const BeginRenewalPeriod = () => {
    const dispatch = useThunkDispatch();
    const { activeDate, teamId } = useTeamProps();
    const currentActiveYear = moment(activeDate).year();
    const nextActiveYear = currentActiveYear + 1;

    const { canViewPathwayBlueprints, isLoading, pathwayBlueprint } = useSelector(
        (state: AppStore) => ({
            canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
            isLoading: hasApiActivity(state, TRANSITION_TO_RENEWING_ACTION),
            pathwayBlueprint: state.pathwayBlueprints.find(
                (x) => x.isActive && x.year === nextActiveYear && x?.teamId === teamId
            ),
        })
    );

    const textToMatch = `Transition this team to Renewing for ${Number(activeDate?.getYear()) + 1}`;

    const [inputValue, setInputValue] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const {
        closeModal: closeRenewalModal,
        isVisible: isRenewalModalVisible,
        openModal: openRenewalModal,
    } = useModalState();

    const {
        openModal: openRenewalCheckingModal,
        closeModal: closeRenewalCheckingModal,
        isVisible: isLaunchModalVisible,
    } = useModalState();

    useEffect(() => {
        if (canViewPathwayBlueprints) {
            dispatch(getTeamPathwayBlueprints(teamId));
        }
    }, [canViewPathwayBlueprints, dispatch, teamId]);

    const onTransitionToRenewal = async () => {
        if (inputValue === textToMatch) {
            setIsInvalid(false);
            dispatch(setSelectedYear(nextActiveYear.toString()));
            await dispatch(transitionToRenewing(teamId));
            closeRenewalModal();
        } else {
            setIsInvalid(true);
        }
    };
    const hasActivePBP = !!pathwayBlueprint;
    const requirements = useMemo(
        () =>
            [
                {
                    description: `Active Pathway Blueprint for ${nextActiveYear ??
                        'Team Active Year'} Set`,
                    requirementMet: hasActivePBP,
                },
            ] as IRequirement[],
        [nextActiveYear, hasActivePBP]
    );

    const modalBodyText =
        'The following requirements must be met before you can transition a team to renewal:';

    const confirmModalBody = (
        <React.Fragment>
            <ul>
                <li>
                    The Active Date for the team and its members will be set to 1/1/
                    {nextActiveYear}.
                </li>
                <li>
                    Household data will be copied from {currentActiveYear} to {nextActiveYear}.
                </li>
                <li>
                    Team Yearly Operations Info will be copied from {currentActiveYear} to{' '}
                    {nextActiveYear}.
                </li>
            </ul>
            <br />
            <br />
            <span>
                Please type the phrase{' '}
                <UnselectableText as="strong">{textToMatch}</UnselectableText> exactly as it appears
                to confirm this action.
            </span>
            <br />
            <br />
            <TextField
                autoFocus
                data-cy="confirm-begin-renewal"
                defaultValue={inputValue}
                error={isInvalid}
                helperText={
                    isInvalid
                        ? 'Input text must match the bolded phrase above exactly to confirm this action'
                        : ''
                }
                onChange={onChange(setInputValue)}
            />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {isLaunchModalVisible && !hasActivePBP && (
                <HandoffModal
                    modalBodyText={modalBodyText}
                    onClose={closeRenewalCheckingModal}
                    requirements={requirements}
                />
            )}
            {isRenewalModalVisible && hasActivePBP && (
                <ConfirmationModal
                    noButtonText="Cancel"
                    onNoClick={closeRenewalModal}
                    onYesClick={onTransitionToRenewal}
                    showActivity={isLoading}
                    title="Begin Renewal Period"
                    yesButtonText="Confirm"
                >
                    {confirmModalBody}
                </ConfirmationModal>
            )}
            <Button
                data-cy="begin-renewal-period"
                isLoading={isLoading}
                onClick={hasActivePBP ? openRenewalModal : openRenewalCheckingModal}
                xsClassName="mb-4"
            >
                Transition Team to Renewing
            </Button>
        </React.Fragment>
    );
};

export default hot(module)(BeginRenewalPeriod);
