import { FinchIntegrationStatuses } from 'api/generated/enums';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import startCase from 'lodash/startCase';
import React from 'react';
import { hot } from 'react-hot-loader';

const FinchIntegrationSection = () => {
    const { team } = useTeamProps();

    return (
        <React.Fragment>
            <ProfileAttribute label="Status">
                {startCase(
                    FinchIntegrationStatuses[
                        team?.finchIntegrationStatus ?? FinchIntegrationStatuses.NotConfigured
                    ]
                )}
            </ProfileAttribute>
            {team?.finchProviderName && (
                <ProfileAttribute label="Provider">
                    {startCase(team?.finchProviderName)}
                </ProfileAttribute>
            )}
        </React.Fragment>
    );
};

export default hot(module)(FinchIntegrationSection);
