import { Grid, Typography } from '@mui/material';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const CustomMaxReimbursementContent = ({
    activeReimbursement,
    addVerticalMargin = false,
}: {
    activeReimbursement?: number;
    addVerticalMargin?: boolean;
}) => (
    <Grid
        alignItems="center"
        container
        direction="column"
        item
        marginY={addVerticalMargin ? 4 : 0}
        spacing={2}
    >
        <Typography fontWeight="bold">
            Maximum Reimbursement Amount*:{' '}
            {`${formatCurrency(activeReimbursement, {
                preserveDecimal: true,
            })} per month`}
        </Typography>
        <Typography marginLeft={2} marginTop={2} variant="body2">
            * You will only be reimbursed up to your total plan costs.
        </Typography>
    </Grid>
);

export default hot(module)(CustomMaxReimbursementContent);
