import { AttachMoney } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import InformationIconTooltip from 'components/InformationIconTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

const TaxSavingsChip = () => {
    const taxSavingsTooltipText =
        'This is an Off-Exchange plan. If you enroll in this plan, you may be eligible to pay for it entirely with tax-free dollars.';
    const taxSavings = (
        <Stack direction="row" fontSize="small" spacing={0.5}>
            <AttachMoney fontSize="small" />
            <span>Potential Tax Savings</span>
            &nbsp;
            <InformationIconTooltip title={taxSavingsTooltipText} variant="secondary" />
        </Stack>
    );
    return <Chip color="secondary" label={taxSavings} size="small" />;
};

export default hot(module)(TaxSavingsChip);
