import Grid from '@mui/material/Grid';
import { Tasks, TaskStatuses } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import UnderConstructionModal from 'components/underConstructionModal/UnderConstructionModal';
import { openUnderConstructionModal } from 'components/underConstructionModal/underConstructionAction';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    getPreviousTask,
    getSupportedEnrollmentUserFlow,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import ShopContent from 'pages/shop/ShopContent';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const TASKS_TO_CHECK_FOR_COMPLETED_STATUS = [Tasks.KeepOrChange, Tasks.RecommendedBenefitsPath];

const ShopForPlansPage = ({ currentTask }: { currentTask: TaskDto }) => {
    const { userFlows } = useSelector((state: AppStore) => ({
        userFlows: state.userFlows,
    }));
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const tasks = getSupportedEnrollmentUserFlow(userFlows)?.tasks as TaskDto[];
    const taskId = currentTask.globalId;
    const showPreviousButton = TASKS_TO_CHECK_FOR_COMPLETED_STATUS.map((t) =>
        getPreviousTask(tasks, t, Tasks.ChooseYourPlan, Tasks.SelectBenefits)
    ).some((t) => t?.taskStatusId === TaskStatuses.Completed);
    const isDesktop = useContext(DesktopContext);

    useEffect(() => {
        if (!isDesktop) {
            dispatch(openUnderConstructionModal());
        }
    }, [dispatch, isDesktop]);

    return (
        <Grid item margin={0} width="100%" xs={12}>
            <UnderConstructionModal />
            <ShopContent
                chunkPlan={currentTask.response}
                flowTaskId={taskId}
                isIchraFlow
                showPreviousButton={showPreviousButton}
                year={user?.activeDate?.getYear() as number}
            />
        </Grid>
    );
};

export default hot(module)(ShopForPlansPage);
