import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Typography from 'components/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const PlanInfoLine = ({
    content,
    iconClickAction,
    label,
    showInfoIcon,
    strikethroughText,
}: {
    content: string[] | string;
    iconClickAction?: () => void;
    label: string;
    showInfoIcon?: boolean;
    strikethroughText?: boolean;
}) => {
    if (typeof content === 'string') {
        content = [content];
    }
    return (
        <Grid container direction="row">
            <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="flex-start"
                spacing={0.75}
                xs={6}
            >
                {showInfoIcon && (
                    <Grid item>
                        <InfoOutlined
                            className="clickable"
                            color="secondary"
                            onClick={iconClickAction}
                        />
                    </Grid>
                )}
                <Grid flex={1} item>
                    <Typography color="GrayText" fontSize="0.75rem" variant="body2">
                        {label}:
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                {content?.map((c) => (
                    <Typography
                        className={strikethroughText ? 'text-muted' : ''}
                        color="primary"
                        fontSize="0.75rem"
                        key={c}
                        sx={strikethroughText ? { textDecoration: 'line-through' } : {}}
                        variant="body2"
                    >
                        {c}
                    </Typography>
                ))}
            </Grid>
        </Grid>
    );
};

export default hot(module)(PlanInfoLine);
