import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import Icon from 'components/Icon';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

export type IRequirement = { description: string; requirementMet: boolean };

type IHandoffModalProps = {
    modalBodyText?: string;
    onClose: () => void;
    requirements: IRequirement[];
};

const HandoffModal = ({ onClose, modalBodyText, requirements }: IHandoffModalProps) => {
    const launchText =
        'Before a team can be handed off to launch there are a number of requirements that must be met.';

    const requirementRows = requirements.map((requirement, index) => (
        <Row className="align-items-center" key={index}>
            {requirement.requirementMet ? (
                <Icon className="pl-3 pr-2" variant="success">
                    checkmark
                </Icon>
            ) : (
                <Icon className="pl-3 pr-2" variant="danger">
                    cross
                </Icon>
            )}
            <Typography
                color={(theme) =>
                    requirement.requirementMet
                        ? theme.palette.success.main
                        : theme.palette.error.main
                }
                variant="h6"
            >
                {requirement.description}
            </Typography>
        </Row>
    ));
    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">Hold Up ✋</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p className="font-weight-bold">{modalBodyText ?? launchText}</p>
                {requirementRows}
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Return to Dashboard</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(HandoffModal);
