import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PlanTypeIds } from 'api/generated/enums';
import {
    IComparedSelectedPlan,
    IHouseholdMemberDto,
    ISelectedPlan,
    IUser,
} from 'api/generated/models';
import MarketplacePlanCardContent from 'components/MarketplacePlanCardContent';
import useUserProps from 'hooks/useUserProps';
import KeepOrChangeBenefitCardCost from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitCardCost';
import KeepOrChangeBenefitCardFooter, {
    SwapIcon,
} from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitCardFooter';
import KeepOrChangeBenefitDetails from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitDetails';
import { getShouldHideCosts } from 'pages/dashboard/taskLists/member/tasks/keepOrChange/keepOrChangeSelectors';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { getTitle } from 'utilities/selectedPlan';

const getMemberNamesForNextYearString = (
    householdMembersIncluded: string[],
    isPrimaryIncluded: boolean,
    user: IUser | undefined,
    householdMembers: IHouseholdMemberDto[] | undefined
) => {
    const members = householdMembersIncluded.reduce<string[]>((names, householdMemberId) => {
        const name = householdMembers?.find((x) => x.householdMemberId === householdMemberId)
            ?.firstName;
        if (name) {
            names.push(name);
        }
        return names;
    }, []);
    if (isPrimaryIncluded && user?.firstName) {
        members.unshift(user.firstName);
    }
    return members.join(', ');
};
const getCoverageForNextYearString = (
    { householdMembersCovered, isPrimaryCovered }: ISelectedPlan,
    user: IUser | undefined,
    householdMembers: IHouseholdMemberDto[]
) =>
    getMemberNamesForNextYearString(
        householdMembersCovered ?? [],
        isPrimaryCovered,
        user,
        householdMembers
    );
const PLAN_TYPES_TO_HIDE_COST = [
    PlanTypeIds.CHIP,
    PlanTypeIds.MediShare,
    PlanTypeIds.Medicaid,
    PlanTypeIds.ParentEmployer,
    PlanTypeIds.SpouseEmployer,
    PlanTypeIds.TermMedical,
    PlanTypeIds.VeteransAffairs,
];

const KeepOrChangeBenefitCard = ({
    isAncillary = false,
    selectedPlan,
}: {
    isAncillary?: boolean;
    selectedPlan: IComparedSelectedPlan | ISelectedPlan;
}) => {
    const comparedSelectedPlan = selectedPlan as IComparedSelectedPlan;
    const { user } = useUserProps();
    const { householdMembers, shouldHideCost } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
        shouldHideCost: getShouldHideCosts(state, selectedPlan.planId),
    }));
    const coverageString = useMemo(
        () => getCoverageForNextYearString(selectedPlan, user, householdMembers),
        [householdMembers, selectedPlan, user]
    );

    const isIneligibleToKeep = hasValue(comparedSelectedPlan.ineligibleToKeepReason);
    const backgroundClass = isIneligibleToKeep ? 'bg-dark-95-percent-transparent' : '';
    const individualOnly =
        (selectedPlan.isPrimaryCovered &&
            (selectedPlan.householdMembersCovered?.length ?? 0) === 0) ||
        (!selectedPlan.isPrimaryCovered &&
            (selectedPlan.householdMembersCovered?.length ?? 0) === 1);
    const showPlanData =
        !isIneligibleToKeep &&
        !PLAN_TYPES_TO_HIDE_COST.includes(selectedPlan.planTypeId) &&
        !shouldHideCost;
    const isMedicare = selectedPlan.planTypeId === PlanTypeIds.Medicare;

    if (coverageString === '') {
        return <React.Fragment />;
    }
    return (
        <Card>
            <CardContent className={`${backgroundClass}`}>
                <Stack gap={3} justifyContent="space-between">
                    <Stack direction="row" justifyContent="space-between">
                        <Stack>
                            <Typography component="span" fontSize={12}>
                                {selectedPlan.issuerName}
                            </Typography>
                            <Typography mb={1} mt={0} variant="h4">
                                {getTitle(selectedPlan, false)}{' '}
                                {comparedSelectedPlan.isPlanCrosswalked && <SwapIcon />}
                            </Typography>
                        </Stack>
                        {showPlanData && (
                            <KeepOrChangeBenefitCardCost
                                isMedicare={isMedicare}
                                selectedPlan={selectedPlan}
                            />
                        )}
                    </Stack>
                    {showPlanData && !isAncillary && !isMedicare && (
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                        >
                            <MarketplacePlanCardContent
                                familyDeductibleCost={selectedPlan.familyDeductibleCost}
                                familyMoopCost={selectedPlan.familyMoopCost}
                                genericDrugCost={selectedPlan.genericDrugCost}
                                individualDeductibleCost={selectedPlan.individualDeductibleCost}
                                individualMoopCost={selectedPlan.individualMoopCost}
                                individualOnly={individualOnly}
                                networkType={selectedPlan.networkType}
                                planName={selectedPlan.planName}
                                primaryCareCost={selectedPlan.primaryCareCost}
                                reimbursementOverlayDto={selectedPlan.reimbursementOverlayDto}
                                specialistCost={selectedPlan.specialistCost}
                            />
                        </Box>
                    )}
                    <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Box>
                            <Typography
                                color={(theme) => theme.palette.muted.main}
                                component="span"
                                mr={1}
                                variant="body2"
                            >
                                Members
                            </Typography>
                            <Typography component="span" fontSize="small" fontWeight="bold">
                                {coverageString}
                            </Typography>
                        </Box>
                        <KeepOrChangeBenefitDetails selectedPlan={selectedPlan} />
                    </Stack>
                </Stack>
            </CardContent>
            <KeepOrChangeBenefitCardFooter
                className={backgroundClass}
                selectedPlan={selectedPlan}
            />
        </Card>
    );
};

export default hot(module)(KeepOrChangeBenefitCard);
