import { InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Collapse, Divider, Grid, Link, Stack } from '@mui/material';
import { PlanListing } from 'api/generated/enums';
import { IMarketplacePlanDto, PathwayRecommendedPlan } from 'api/generated/models';
import Button from 'components/Button';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import ReimbursementOverlayModal from 'components/reimbursementOverlayModal/ReimbursementOverlayModal';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useModalState from 'hooks/useModalState';
import { MultiRecommendedPlanContext } from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/MultiRecommendedPlanContent';
import PlanInfoLine from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/PlanInfoLine';
import HsaEligibleChip from 'pages/shop/HsaEligibleChip';
import PlanCardProviders from 'pages/shop/PlanCardProviders';
import { IShoppingPlan } from 'pages/shop/shopping';
import TaxSavingsChip from 'pages/shop/TaxSavingsChip';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';
import {
    getReimbursementOverlayLabel,
    shouldDropDecimal,
    shouldShowFamilyMoopWithRhReimbursement,
    shouldShowRhReimbursement,
} from 'utilities/reimbursementOverlay';

const RecommendedPlanItem = ({
    handleSubmit,
    marketplacePlan,
    plan,
    showTaxSavings,
}: {
    handleSubmit: (plan?: IMarketplacePlanDto) => Promise<void>;
    marketplacePlan?: IMarketplacePlanDto;
    plan: PathwayRecommendedPlan;
    showTaxSavings: boolean;
}) => {
    const reimbursementOverlayDto = marketplacePlan?.reimbursementOverlayDto;
    const preserveDecimal = !shouldDropDecimal(reimbursementOverlayDto);

    const { detailsExpanded, setDetailsExpanded, individualOnly } = useContext(
        MultiRecommendedPlanContext
    );
    const {
        closeModal: closeReimbursementOverlayModal,
        isVisible: isReimbursementOverlayModalVisible,
        openModal: openReimbursementOverlayModal,
    } = useModalState();
    const deductibleContent = [
        `${formatCurrency(marketplacePlan?.individualDeductibleCost)} per member`,
    ];
    if (hasValue(marketplacePlan?.familyDeductibleCost) && !individualOnly) {
        deductibleContent.push(
            `${formatCurrency(marketplacePlan?.familyDeductibleCost)} per family`
        );
    }
    const moopContent = [`${formatCurrency(marketplacePlan?.individualMoopCost)} per member`];
    if (hasValue(marketplacePlan?.familyMoopCost) && !individualOnly) {
        moopContent.push(`${formatCurrency(marketplacePlan?.familyMoopCost)} per family`);
    }
    const reimbursementContent = [];
    if (shouldShowRhReimbursement(reimbursementOverlayDto)) {
        if (hasValue(reimbursementOverlayDto?.individualMoopWithRhReimbursement)) {
            reimbursementContent.push(
                `${formatCurrency(reimbursementOverlayDto?.individualMoopWithRhReimbursement, {
                    preserveDecimal,
                })} per member`
            );
        }
        if (shouldShowFamilyMoopWithRhReimbursement(reimbursementOverlayDto)) {
            reimbursementContent.push(
                `${formatCurrency(reimbursementOverlayDto?.familyMoopWithRhReimbursement, {
                    preserveDecimal,
                })} per family`
            );
        }
    }

    return (
        <React.Fragment>
            {isReimbursementOverlayModalVisible && (
                <ReimbursementOverlayModal
                    familyMoopCost={marketplacePlan?.familyMoopCost}
                    individualMoopCost={marketplacePlan?.individualMoopCost}
                    onClose={closeReimbursementOverlayModal}
                    planName={marketplacePlan?.name}
                    reimbursementOverlayDto={marketplacePlan?.reimbursementOverlayDto}
                />
            )}
            {hasValue(plan.planLabel) && (
                <Grid item>
                    <Typography fontWeight={700} variant="h4">
                        {plan.planLabel}{' '}
                        {hasValue(plan.description) && (
                            <Tooltip title={plan.description}>
                                <InfoOutlined color="secondary" />
                            </Tooltip>
                        )}
                    </Typography>
                </Grid>
            )}
            <Grid item px={1} xs={12}>
                <Card>
                    <CardContent sx={{ paddingBottom: '0px !important' }}>
                        <Stack>
                            <Typography color="GrayText" m={0} variant="h6">
                                {marketplacePlan?.issuer?.name}
                            </Typography>
                            <Box alignItems="center" display="flex" minHeight={70}>
                                <Typography mt={0} variant="h5">
                                    {marketplacePlan?.name}
                                </Typography>
                            </Box>
                            <Stack direction="row" spacing={1}>
                                {showTaxSavings &&
                                    plan.benchmarkType === PlanListing.OffExchange && (
                                        <TaxSavingsChip />
                                    )}
                                <HsaEligibleChip
                                    isHsaEligible={marketplacePlan?.isHsaEligible ?? false}
                                />
                            </Stack>
                            <Divider sx={{ my: 2 }} />
                        </Stack>
                        <Stack rowGap={1.5}>
                            <PlanInfoLine content={deductibleContent} label="Deductible" />
                            <PlanInfoLine
                                content={moopContent}
                                label="Max Out of Pocket"
                                strikethroughText={hasValue(
                                    marketplacePlan?.reimbursementOverlayDto?.displayLevelId
                                )}
                            />
                            {arrayHasValue(reimbursementContent) && (
                                <PlanInfoLine
                                    content={reimbursementContent}
                                    iconClickAction={openReimbursementOverlayModal}
                                    label={`With ${getReimbursementOverlayLabel(
                                        reimbursementOverlayDto
                                    )}Reimbursement`}
                                    showInfoIcon
                                />
                            )}
                            <Divider sx={{ mb: 0, mt: 2 }} />
                        </Stack>
                        <Stack sx={{ mt: 0 }}>
                            <Collapse in={detailsExpanded}>
                                <Stack rowGap={1.5} sx={{ minHeight: 210, mt: 2 }}>
                                    <PlanInfoLine
                                        content={marketplacePlan?.planType as string}
                                        label="Network Type"
                                    />
                                    <PlanInfoLine
                                        content={marketplacePlan?.primaryCareCost as string}
                                        label="Primary Care Visit"
                                    />
                                    <PlanInfoLine
                                        content={marketplacePlan?.specialistCost as string}
                                        label="Specialist Visit"
                                    />
                                    <PlanInfoLine
                                        content={marketplacePlan?.genericDrugCost as string}
                                        label="Generic Drugs"
                                    />
                                    <PlanCardProviders
                                        plan={
                                            {
                                                isHsaEligible: marketplacePlan?.isHsaEligible,
                                                ...marketplacePlan,
                                            } as IShoppingPlan
                                        }
                                        showProvidersOverride
                                    />
                                    {hasValue(marketplacePlan?.benefitsUrl) && (
                                        <Link
                                            color="secondary"
                                            fontSize="0.75rem"
                                            href={marketplacePlan?.benefitsUrl}
                                            marginTop={1}
                                        >
                                            Summary of Benefits
                                        </Link>
                                    )}
                                </Stack>
                            </Collapse>
                            <Button
                                className="clickable"
                                noPadding
                                onClick={() => setDetailsExpanded?.(!detailsExpanded)}
                                variant="text"
                            >
                                <span style={{ fontSize: '0.75rem' }}>
                                    {detailsExpanded ? 'Collapse' : 'Expand'} Details
                                </span>
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid alignItems="center" container direction="column" item justifyContent="flex-end">
                <Typography color="secondary" marginBottom={2} textAlign="center" variant="h5">
                    Your Cost:{' '}
                    {formatCurrency(
                        marketplacePlan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost,
                        { preserveDecimal: true }
                    )}{' '}
                    per month{' '}
                    <ReimbursementMyCostTooltip
                        memberCost={
                            marketplacePlan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost
                        }
                        planCost={marketplacePlan?.premiumWithCredits}
                        reimbursement={
                            marketplacePlan?.majorMedicalBenefitCost?.reimbursementAmount
                        }
                    />
                </Typography>
                <Button noPadding onClick={async () => handleSubmit(marketplacePlan)} size="small">
                    Select
                </Button>
            </Grid>
        </React.Fragment>
    );
};

export default hot(module)(RecommendedPlanItem);
