import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InformationIconTooltip from 'components/InformationIconTooltip';
import { HEADER_FONT_COLOR } from 'constants/styleConstants';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

export type IRecommendedPlanBookmark = {
    description?: string;
    label?: string;
};

const RecommendedPlanBookmark = ({ description, label }: IRecommendedPlanBookmark) => (
    <Grid>
        <Chip
            label={
                <Grid alignItems="center" container>
                    <Typography color={HEADER_FONT_COLOR} variant="h5" whiteSpace="nowrap">
                        Recommended Plan{hasValue(label) ? ` - ${label}` : ''}
                    </Typography>
                    {hasValue(description) && (
                        <Box marginLeft={1}>
                            <InformationIconTooltip title={description} />
                        </Box>
                    )}
                </Grid>
            }
            sx={{
                backgroundColor: '#cde8f7',
                borderRadius: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }}
        />
    </Grid>
);

export default hot(module)(RecommendedPlanBookmark);
