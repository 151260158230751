import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { getSelectedPlansForReview } from 'actions/selectedPlan/getSelectedPlansForReview';
import { ISelectedPlan, ISelectedPlanDto } from 'api/generated/models';
import Button from 'components/Button';
import MediShareDisclaimerLabel from 'components/MediShareDisclaimerLabel';
import RemovePlanModal from 'components/RemovePlanModal';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import styled from 'styled-components';
import { getDisplayFirstName } from 'utilities/household';
import { isMediShare } from 'utilities/selectedPlan';

const Icon = styled.i`
    font-size: 16px;
`;

export const PersonOnPlan = ({
    isPrimary = false,
    name,
}: {
    isPrimary?: boolean;
    name: string | undefined;
}) => (
    <span data-cy="household-member">
        {isPrimary ? (
            <Icon className="dripicons-user m-1" />
        ) : (
            <Icon className="dripicons-user-group m-1" />
        )}
        {name}
    </span>
);

type ISelectionCardProps = { selectedPlan: ISelectedPlanDto };

const SelectionCard = ({ selectedPlan }: ISelectionCardProps) => {
    const {
        householdMembersCovered,
        isPrimaryCovered,
        issuerName,
        planName,
        selectedPlanId,
    } = selectedPlan;
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const { householdMembers } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
    }));
    const { openModal, closeModal, isVisible } = useModalState();
    const planFor = useMemo(() => {
        let personsOnPlan: ReactNode[] = [];
        if (householdMembers.length) {
            personsOnPlan =
                householdMembersCovered?.map((id) => (
                    <PersonOnPlan
                        key={id}
                        name={find(householdMembers, (x) => x.householdMemberId === id)?.firstName}
                    />
                )) ?? [];
        }
        if (isPrimaryCovered) {
            personsOnPlan.unshift(
                <PersonOnPlan isPrimary key={1} name={getDisplayFirstName(user)} />
            );
        }
        return personsOnPlan;
    }, [householdMembers, householdMembersCovered, isPrimaryCovered, user]);
    const isMediSharePlanType = isMediShare(selectedPlan);
    return (
        <React.Fragment>
            {isVisible && (
                <RemovePlanModal
                    onClose={closeModal}
                    onSubmit={async () => dispatch(getSelectedPlansForReview(user?.userId))}
                    plan={
                        {
                            householdMembersCovered,
                            isPrimaryCovered,
                            planName,
                            selectedPlanId,
                        } as ISelectedPlan
                    }
                    userId={user?.userId}
                />
            )}
            <Card data-cy="selected-plan-card">
                <CardContent>
                    <Row className="px-2 align-items-center justify-content-between">
                        <div>
                            <Typography variant="h3">
                                {issuerName} - {planName}
                            </Typography>
                        </div>
                    </Row>
                    <hr />
                    <Row className="justify-content-between mx-2">
                        <Col as={Row} lg="8">
                            <Row className="align-items-center">This plan is for: {planFor}</Row>
                        </Col>
                        <Col as={Row} className="justify-content-end" lg="4">
                            <Button
                                color="error"
                                data-cy={`remove-plan-${kebabCase(issuerName)}`}
                                noPadding
                                onClick={openModal}
                            >
                                Remove Plan
                            </Button>
                        </Col>
                    </Row>
                    {isMediSharePlanType && <MediShareDisclaimerLabel />}
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default hot(module)(SelectionCard);
