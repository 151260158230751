import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useTheme,
} from '@mui/material';
import { clearImportedAgeBandedTable } from 'actions/clear';
import { ADD_ICHRA_CLASS_ACTION, addIchraClass } from 'actions/pathwayBlueprint/addIchraClass';
import { APPLY_STRATEGY_ACTION, applyStrategy } from 'actions/pathwayBlueprint/applyStrategy';
import { EDIT_ICHRA_CLASS_ACTION, editIchraClass } from 'actions/pathwayBlueprint/editIchraClass';
import {
    IMPORT_AGE_BANDED_TABLE_ACTION,
    importAgeBandedTable,
} from 'actions/pathwayBlueprint/importAgeBandedTable';
import { AgeBandSizes, PlanListing, TopLineReimbursementStrategies } from 'api/generated/enums';
import { IApplyIchraClassStrategyResponseDto, IIchraClass } from 'api/generated/models';
import Button from 'components/Button';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { IchraClassModalContext } from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import IchraClassForm, {
    ichraClassSchema,
} from 'pages/pathwayBlueprint/ichraClasses/IchraClassForm';
import { clearPathwayInputs, setPathwayInputs } from 'pages/pathwayBlueprint/pathwayModalActions';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatErrors, IFormErrors, IValidateData, validate } from 'utilities/forms';
import { arrayHasValue, hasValue } from 'utilities/index';
import { ValidationError } from 'yup';

const APPLICABLE_STRATEGIES = [
    TopLineReimbursementStrategies.CoverageLevelPercentage,
    TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
];

const setInitialValues = (
    ichraClass: IIchraClass,
    seedValues: {
        ageBandSizeId: AgeBandSizes;
        dependentKickerThreshold: number;
        ecRatio: number;
        eeRatio: number;
        efRatio: number;
        esRatio: number;
        topLineReimbursementStrategyId: TopLineReimbursementStrategies;
    }
) => {
    const seedKeys = Object.keys(seedValues) as (keyof typeof seedValues)[];

    seedKeys.forEach((seed) => {
        if (!hasValue(ichraClass[seed])) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (ichraClass as any)[seed] = seedValues[seed];
        }
    });
};

type IIchraClassFormContext = {
    errors?: IFormErrors<ReturnType<typeof ichraClassSchema>>;
    ichraClass: IIchraClass;
    importedAgeBandedTableFile: File | File[] | undefined;
    setFile: (data: File | undefined) => void;
    setIchraClass: Dispatch<SetStateAction<IIchraClass>>;
};

export const IchraClassFormContext = React.createContext<IIchraClassFormContext>(
    ({} as unknown) as IIchraClassFormContext
);

const IchraClassModal = () => {
    const dispatch = useThunkDispatch();
    const theme = useTheme();
    const { closeIchraClassModal, ichraClassGlobalId } = useContext(IchraClassModalContext);
    const {
        appliedStrategy,
        isLoading,
        pathwayBlueprint,
        pathwayInputs,
        stateAbbreviation,
    } = useSelector((state: AppStore) => ({
        appliedStrategy: state.appliedStrategy,
        isLoading: hasApiActivity(
            state,
            ADD_ICHRA_CLASS_ACTION,
            EDIT_ICHRA_CLASS_ACTION,
            APPLY_STRATEGY_ACTION,
            IMPORT_AGE_BANDED_TABLE_ACTION
        ),
        pathwayBlueprint: state.pathwayBlueprint,
        pathwayInputs: state.pathwayModalState.pathwayInputs,
        stateAbbreviation: state.counties[0]?.stateAbbrev,
    }));
    const [ichraClass, setIchraClass] = useState<IIchraClass>({} as IIchraClass);

    const selectedIchraClass = pathwayBlueprint?.ichraClasses?.find(
        (ic) => ic.globalId === ichraClassGlobalId
    );
    const [needsToApplyStrategy, setNeedsToApplyStrategy] = useState<boolean>(
        selectedIchraClass?.isStale ?? false
    );
    const [needsToProcessFileChange, setNeedsToProcessFileChange] = useState(false);

    const [importedAgeBandedTableFile, setImportAgeBandedTableFile] = useState<
        File | File[] | undefined
    >();
    const prevIchraClassRef = useRef<IIchraClass>({} as IIchraClass);

    const setFile = useCallback(
        (data: File | undefined) => {
            setNeedsToProcessFileChange(true);
            setImportAgeBandedTableFile(data);
            if (hasValue(data)) {
                dispatch(clearImportedAgeBandedTable());
                setIchraClass({ ...ichraClass, importedAgeBandedTable: undefined });
            }
        },
        [dispatch, ichraClass]
    );
    const { errors } = pathwayInputs ?? {};

    const hasIchraClassChanged = (prev: IIchraClass, current: IIchraClass): boolean => {
        if (hasValue(prev.topLineReimbursementStrategyId)) {
            const keys = Object.keys({ ...prev, ...current });
            const excludedKeys = [
                'additionalDependentKicker',
                'ec64',
                'ee64',
                'ef64',
                'es64',
                'name',
            ];

            for (const key of keys) {
                if (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (prev as any)[key] !== (current as any)[key] &&
                    !excludedKeys.includes(key)
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    const [needsToImport, setNeedsToImport] = useState(false);

    const saveIchraClass = async () => {
        const data = {
            additionalDependentKicker: ichraClass.additionalDependentKicker?.toString(),
            ageBandSizeId: ichraClass.ageBandSizeId.toString(),
            county: ichraClass.county,
            dependentKickerCoveragePercent: ichraClass.dependentKickerCoveragePercentage?.toString(),
            dependentKickerThreshold: ichraClass.dependentKickerThreshold.toString(),
            ec64: ichraClass.ec64?.toString(),
            ecCoveragePercent: ichraClass.ecCoveragePercentage?.toString(),
            ecRatio: ichraClass.ecRatio?.toString(),
            ee64: ichraClass.ee64?.toString(),
            eeCoveragePercent: ichraClass.eeCoveragePercentage?.toString(),
            eeRatio: ichraClass.eeRatio?.toString(),
            ef64: ichraClass.ef64?.toString(),
            efCoveragePercent: ichraClass.efCoveragePercentage?.toString(),
            efRatio: ichraClass.efRatio?.toString(),
            es64: ichraClass.es64?.toString(),
            esCoveragePercent: ichraClass.esCoveragePercentage?.toString(),
            esRatio: ichraClass.esRatio?.toString(),
            ichraBenchmarkType: ichraClass.benchmarkPlanType,
            importedAgeBandedTable: ichraClass.importedAgeBandedTable,
            marketplaceCarrier: ichraClass.benchmarkCarrier,
            marketplacePlanId: ichraClass.benchmarkPlanId,
            name: ichraClass.name,
            otherHouseholdMemberCoveragePercentage:
                ichraClass.otherHouseholdMemberCoveragePercentage,
            topLineReimbursementStrategyId: ichraClass.topLineReimbursementStrategyId.toString(),
            zipCode: ichraClass?.zipCode,
        };
        try {
            if (needsToImport) {
                await dispatch(importAgeBandedTable(importedAgeBandedTableFile as File));
                return;
            }
            await validate(
                ichraClassSchema(needsToApplyStrategy, ichraClass.topLineReimbursementStrategyId),
                data as IValidateData<ReturnType<typeof ichraClassSchema>>
            );
            if (needsToApplyStrategy) {
                dispatch(applyStrategy(ichraClass));
                dispatch(setPathwayInputs({ errors: null }));
                setNeedsToApplyStrategy(false);
                setIchraClass({ ...ichraClass, isStale: false });
            } else {
                if (hasValue(selectedIchraClass)) {
                    dispatch(editIchraClass(ichraClass));
                } else {
                    dispatch(addIchraClass(ichraClass));
                }
                closeIchraClassModal?.();
                dispatch(clearPathwayInputs());
            }
        } catch (errors) {
            dispatch(setPathwayInputs({ errors: formatErrors(errors as ValidationError) }));
        }
    };

    const ichraClassFormContextValue = useMemo(
        () => ({
            errors,
            ichraClass,
            importedAgeBandedTableFile,
            setFile,
            setIchraClass,
        }),
        [errors, ichraClass, importedAgeBandedTableFile, setFile]
    );

    const getButtonLabel = () => {
        let buttonLabel = 'Save';
        if (needsToApplyStrategy) {
            buttonLabel = 'Apply Strategy';
        } else if (needsToImport) {
            buttonLabel = 'Import';
        }
        return buttonLabel;
    };
    useEffect(() => {
        const ichraClassToAdd = { ...ichraClass };
        if (APPLICABLE_STRATEGIES.includes(ichraClass.topLineReimbursementStrategyId)) {
            // Can't use hasValue here in case the value is ""
            if (pathwayInputs?.ichraBenchmarkType !== undefined) {
                ichraClassToAdd.benchmarkPlanType = (pathwayInputs?.ichraBenchmarkType as unknown) as PlanListing;
            }
            // Can't use hasValue here in case the value is ""
            if (pathwayInputs?.county !== undefined) {
                ichraClassToAdd.county = pathwayInputs?.county;
            }
            // Can't use hasValue here in case the value is ""
            if (pathwayInputs?.marketplaceCarrier !== undefined) {
                ichraClassToAdd.benchmarkCarrier = pathwayInputs?.marketplaceCarrier;
            }
            // Can't use hasValue here in case the value is ""
            if (pathwayInputs?.marketplacePlanId !== undefined) {
                ichraClassToAdd.benchmarkPlanId = pathwayInputs?.marketplacePlanId;
            }
            // Can't use hasValue here in case the value is ""
            if (pathwayInputs?.zipCode !== undefined) {
                ichraClassToAdd.zipCode = pathwayInputs?.zipCode;
            }
            // Can't use hasValue here in case the value is ""
            if (stateAbbreviation !== undefined) {
                ichraClassToAdd.state = stateAbbreviation;
            }
        }
        setIchraClass(ichraClassToAdd);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pathwayInputs?.ichraBenchmarkType,
        pathwayInputs?.county,
        pathwayInputs?.marketplaceCarrier,
        pathwayInputs?.marketplacePlanId,
        pathwayInputs?.zipCode,
        ichraClass.topLineReimbursementStrategyId,
        stateAbbreviation,
    ]);

    useEffect(() => {
        const ichraClassToAdd: IIchraClass = { ...ichraClass };

        const mappings: {
            [key in keyof Partial<IApplyIchraClassStrategyResponseDto>]: keyof Pick<
                IIchraClass,
                | 'additionalDependentKicker'
                | 'ec64'
                | 'ecRatio'
                | 'ee64'
                | 'eeRatio'
                | 'ef64'
                | 'efRatio'
                | 'es64'
                | 'esRatio'
            >;
        } = {
            additionalDependentKicker: 'additionalDependentKicker',
            sixtyFourEcReimbursement: 'ec64',
            sixtyFourEeReimbursement: 'ee64',
            sixtyFourEfReimbursement: 'ef64',
            sixtyFourEsReimbursement: 'es64',
            strategyEcRatio: 'ecRatio',
            strategyEeRatio: 'eeRatio',
            strategyEfRatio: 'efRatio',
            strategyEsRatio: 'esRatio',
        };

        for (const sourceKey in mappings) {
            const targetKey = mappings[sourceKey as keyof IApplyIchraClassStrategyResponseDto];
            if (targetKey) {
                ichraClassToAdd[targetKey] =
                    appliedStrategy[sourceKey as keyof IApplyIchraClassStrategyResponseDto];
            }
        }

        setIchraClass(ichraClassToAdd);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedStrategy]);

    useEffect(() => {
        if (
            ichraClass.isStale ||
            (APPLICABLE_STRATEGIES.includes(ichraClass.topLineReimbursementStrategyId) &&
                hasIchraClassChanged(prevIchraClassRef.current, ichraClass))
        ) {
            setNeedsToApplyStrategy(true);
        } else {
            setNeedsToApplyStrategy(false);
        }

        prevIchraClassRef.current = ichraClass;
    }, [ichraClass]);

    useEffect(() => {
        if (hasValue(selectedIchraClass)) {
            setIchraClass(selectedIchraClass);
            dispatch(
                setPathwayInputs({
                    county: selectedIchraClass.county,
                    ichraBenchmarkType: selectedIchraClass?.benchmarkPlanType?.toString(),
                    marketplaceCarrier: selectedIchraClass.benchmarkCarrier,
                    marketplacePlanId: selectedIchraClass.benchmarkPlanId,
                    zipCode: selectedIchraClass.zipCode,
                })
            );
        } else {
            const newIchraClass = { ...ichraClass, pathwayBlueprintId: pathwayBlueprint.id };
            const seedValues = {
                ageBandSizeId: AgeBandSizes.Five,
                dependentKickerThreshold: 1,
                ecRatio: 3,
                eeRatio: 3,
                efRatio: 3,
                esRatio: 3,
                topLineReimbursementStrategyId: TopLineReimbursementStrategies.Custom,
            };
            dispatch(clearPathwayInputs());
            setInitialValues(newIchraClass, seedValues);
            setIchraClass(newIchraClass);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ichraClassGlobalId, selectedIchraClass]);

    useEffect(() => {
        setNeedsToImport(
            (ichraClass.topLineReimbursementStrategyId === TopLineReimbursementStrategies.Import &&
                !arrayHasValue(ichraClass.importedAgeBandedTable)) ||
                (needsToProcessFileChange && hasValue(importedAgeBandedTableFile))
        );
        setNeedsToProcessFileChange(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ichraClass.importedAgeBandedTable,
        ichraClass.topLineReimbursementStrategyId,
        importedAgeBandedTableFile,
    ]);

    useEffect(
        () => () => {
            dispatch(clearPathwayInputs());
        },
        [dispatch]
    );

    return (
        <Dialog fullWidth maxWidth="lg" onClose={closeIchraClassModal} open>
            <DialogTitle
                sx={{
                    borderBottom: `1px ${theme.palette.neutral.main} solid`,
                    mb: 0,
                    paddingBottom: 0,
                }}
            >
                <Typography textAlign="left" variant="h4">
                    {ichraClassGlobalId ? 'Edit' : 'Add'} ICHRA Class
                </Typography>
            </DialogTitle>

            <DialogContent>
                <IchraClassFormContext.Provider value={ichraClassFormContextValue}>
                    <IchraClassForm />
                </IchraClassFormContext.Provider>
            </DialogContent>
            <DialogActions
                sx={{
                    alignItems: 'center',
                    borderTop: `1px ${theme.palette.neutral.main} solid`,
                    justifyContent: 'center',
                    padding: 2,
                    width: '100%',
                }}
            >
                <Button onClick={closeIchraClassModal} variant="outlined">
                    Close
                </Button>
                <Button
                    disabled={needsToImport && !hasValue(importedAgeBandedTableFile)}
                    isLoading={isLoading}
                    onClick={saveIchraClass}
                    variant={needsToApplyStrategy || needsToImport ? 'outlined' : 'contained'}
                >
                    {getButtonLabel()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(IchraClassModal);
