import ArrowForward from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import { GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_PATHWAY_ACTION } from 'actions/pathwayBlueprint/getPathway';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { COMPLETE_TASK_ACTION } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { IMarketplacePlanDto, PathwayRecommendedPlan, TaskDto } from 'api/generated/models';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import useUserProps from 'hooks/useUserProps';
import { ichraFlowSkeletonRowProps } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import RecommendedPlansContent from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/RecommendedPlansContent';
import { PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT } from 'pages/dashboard/medicalBenefitSelection/OnOrOffExchangePathwayPlanDetails';
import React, { createContext, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { arrayHasValue } from 'utilities/index';

export type IMultiRecommendedPlanContext = {
    detailsExpanded: boolean;
    individualOnly: boolean;
    setDetailsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MultiRecommendedPlanContext = createContext<IMultiRecommendedPlanContext>({
    detailsExpanded: false,
    individualOnly: false,
});

const MultiRecommendedPlanContent = ({
    currentTask,
    excludedMembers,
    handleSubmit,
    isPrimaryCovered,
    plans,
    recommendedPlans,
}: {
    currentTask: TaskDto;
    excludedMembers: string[];
    handleSubmit: (plan?: IMarketplacePlanDto) => Promise<void>;
    isPrimaryCovered: boolean;
    plans: IMarketplacePlanDto[];
    recommendedPlans?: PathwayRecommendedPlan[];
}) => {
    const {
        ancillaryBenefits,
        hasFetchedHousehold,
        householdMembers,
        isCompletingTask,
        isLoadingPathway,
        isLoadingPlan,
        isLoadingVisibleFlows,
    } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        hasFetchedHousehold: hasCompletedRequest(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        householdMembers: state.householdMembers,
        isCompletingTask: hasApiActivity(state, COMPLETE_TASK_ACTION),
        isLoadingPathway: hasApiActivity(
            state,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_PATHWAY_ACTION
        ),
        isLoadingPlan: hasApiActivity(state, GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION),
        isLoadingVisibleFlows: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const { user } = useUserProps();
    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
    const numPlans = plans.length;
    const skeletonWidth = `calc((100% / ${numPlans}) - ((${numPlans} - 1) * 18px))`;
    const isLoading =
        isLoadingPathway || isLoadingPlan || !hasFetchedHousehold || isLoadingVisibleFlows;
    const householdMembersCovered = householdMembers.filter(
        (h) => !excludedMembers.includes(h.householdMemberId)
    );
    const getNamesOfCovered = () => {
        const hhmNames = householdMembersCovered.map((h) => h.firstName);
        if (isPrimaryCovered) {
            hhmNames.unshift(user?.firstName);
        }

        return hhmNames;
    };
    const individualOnly =
        (isPrimaryCovered && householdMembersCovered.length === 0) ||
        (!isPrimaryCovered && householdMembersCovered.length === 1);
    const contextValue = useMemo(() => ({ detailsExpanded, individualOnly, setDetailsExpanded }), [
        detailsExpanded,
        individualOnly,
    ]);
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);

    return (
        <MultiRecommendedPlanContext.Provider value={contextValue}>
            <Grid item xs={12}>
                <IchraFlowTitle title="Your Recommended Benefits Options" />
            </Grid>
            <Grid
                container
                direction={{ md: 'row', xs: 'column' }}
                item
                justifyContent="space-between"
                spacing={4}
                xs={12}
            >
                <Skeleton
                    count={numPlans}
                    height={440}
                    isEnabled={isLoading}
                    rowProps={{ ...ichraFlowSkeletonRowProps }}
                    sx={{ mt: 10 }}
                    width={skeletonWidth}
                >
                    <RecommendedPlansContent
                        handleSubmit={handleSubmit}
                        plansFromMarketplace={plans}
                        recommendedPlans={recommendedPlans as PathwayRecommendedPlan[]}
                    />
                </Skeleton>
            </Grid>
            <Grid
                container
                direction="row"
                item
                justifyContent="space-evenly"
                marginTop={2}
                xs={12}
            >
                <Grid item mb={2} xs={12}>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography textAlign="center" variant="h5">
                        These plans are for:{' '}
                        <span style={{ fontWeight: 700 }}>
                            {getNamesOfCovered().joinSerialComma()}
                        </span>
                        <br />
                        If you need to change this, continue to See All Plans.
                    </Typography>
                </Grid>
                <Stack alignItems="center" direction="row" gap={2}>
                    <IchraFlowButtons
                        currentTaskId={currentTask.globalId}
                        displayAsRow
                        hideContinueButton
                        isLoading={isLoading}
                        redirectToDashboard={redirectToDashboard}
                        showWaiveButton
                    />
                    <Button
                        color="secondary"
                        disabled={isLoadingPlan}
                        isLoading={isCompletingTask}
                        onClick={async () => handleSubmit()}
                        size="small"
                        sx={{ alignItems: 'center' }}
                        variant="outlined"
                        xs={12}
                    >
                        See all plans <ArrowForward sx={{ fontSize: '0.9rem' }} />
                    </Button>
                </Stack>
            </Grid>
            <Grid item width="100%" xs={12}>
                <Divider />
            </Grid>
            <Grid container item paddingX={{ md: 10, xs: 3 }} paddingY={1} xs={12}>
                <Typography fontSize="0.75rem" variant="body2">
                    {PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT}
                </Typography>
            </Grid>
        </MultiRecommendedPlanContext.Provider>
    );
};

export default hot(module)(MultiRecommendedPlanContent);
