import { Switch } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HouseholdMemberInfoDto } from 'api/generated/models';
import InformationIconTooltip from 'components/InformationIconTooltip';
import { handleNeedsCoverage, HhmContext } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

type IHouseholdMemberCard = {
    form: JSX.Element;
    handleEditCancelButtonClick: () => void;
    hhmIndex: number;
    initialHhm?: HouseholdMemberInfoDto;
    isEdited: boolean;
    isExpanded: boolean;
    removeHhm: (hhmIndex: number) => void;
};

const HouseholdMemberCard = ({
    form,
    handleEditCancelButtonClick,
    hhmIndex,
    initialHhm,
    isEdited,
    isExpanded,
    removeHhm,
}: IHouseholdMemberCard) => {
    const { hhmsNeedsMedicalCoverage, isRenewalFlow, setHhmsNeedsMedicalCoverage } = useContext(
        HhmContext
    );

    const needsMedicalCoverage = handleNeedsCoverage(
        initialHhm,
        isRenewalFlow,
        hhmsNeedsMedicalCoverage
    );

    const needsCoverageTooltip = (
        <Stack gap={1}>
            <Typography fontSize="0.75rem">
                Select this household member as needing medical coverage if you wish to include them
                when reviewing medical plan options.
            </Typography>
            <Typography fontSize="0.75rem">
                Choosing members who require coverage could affect your maximum monthly
                reimbursement amount.
            </Typography>
        </Stack>
    );

    const handleNeedsCoverageChange = () => {
        if (needsMedicalCoverage) {
            if (hasValue(initialHhm?.householdMemberId)) {
                setHhmsNeedsMedicalCoverage?.(
                    hhmsNeedsMedicalCoverage?.filter((h) => h !== initialHhm?.householdMemberId)
                );
            } else {
                setHhmsNeedsMedicalCoverage?.(
                    hhmsNeedsMedicalCoverage?.filter(
                        (h) => h !== `${initialHhm?.firstName} - ${initialHhm?.birthdate}`
                    )
                );
            }
        } else {
            if (hasValue(initialHhm?.householdMemberId)) {
                setHhmsNeedsMedicalCoverage?.([
                    ...(hhmsNeedsMedicalCoverage as string[]),
                    initialHhm?.householdMemberId as string,
                ]);
            } else {
                setHhmsNeedsMedicalCoverage?.([
                    ...(hhmsNeedsMedicalCoverage as string[]),
                    `${initialHhm?.firstName} - ${initialHhm?.birthdate}`,
                ]);
            }
        }
    };

    return (
        <Stack
            alignItems="flex-start"
            direction="column"
            justifyContent="space-between"
            spacing={2}
        >
            <Accordion
                disableGutters
                elevation={0}
                expanded={isExpanded}
                sx={{
                    flexDirection: 'row-reverse',
                    width: 'auto',
                }}
            >
                <AccordionSummary
                    sx={{
                        cursor: 'default !important',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container>
                        <Grid item lg={4} xs={12}>
                            <Typography alignItems="center" variant="h5">
                                {initialHhm?.firstName} {initialHhm?.lastName}{' '}
                            </Typography>
                            <Typography variant="body2">
                                Age: {initialHhm?.birthdate.getAge()}
                            </Typography>
                            <Typography variant="body2">Gender: {initialHhm?.gender}</Typography>
                        </Grid>
                        <Grid alignItems="center" container item justifyContent="flex-end" xs={4}>
                            {isEdited && (
                                <Typography fontStyle="italic" textAlign="right" variant="body2">
                                    unsaved edits
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            alignItems="center"
                            container
                            direction="row"
                            gap={2}
                            item
                            justifyContent="flex-end"
                            lg={4}
                            xs={12}
                        >
                            {isRenewalFlow && (
                                <Stack alignItems="center" justifyContent="center">
                                    <Typography fontSize="0.75rem" variant="body2">
                                        Needs Medical Coverage{' '}
                                        <InformationIconTooltip title={needsCoverageTooltip} />
                                    </Typography>

                                    <Switch
                                        checked={needsMedicalCoverage}
                                        color="secondary"
                                        onChange={handleNeedsCoverageChange}
                                        size="small"
                                    />
                                </Stack>
                            )}
                            <Button
                                color="primary"
                                onClick={handleEditCancelButtonClick}
                                sx={{ paddingX: 4 }}
                                variant="text"
                            >
                                {isExpanded ? 'Cancel' : 'Edit'}
                            </Button>
                            <Button
                                color="error"
                                onClick={() => removeHhm(hhmIndex)}
                                sx={{ paddingX: 2 }}
                                variant="text"
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: 3 }}>
                    <Divider sx={{ marginBottom: 4 }} />
                    {form}
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

export default hot(module)(HouseholdMemberCard);
