import ArrowForward from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import { GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_PATHWAY_ACTION } from 'actions/pathwayBlueprint/getPathway';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { COMPLETE_TASK_ACTION } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { IMarketplacePlanDto, MarketplacePlanDto, TaskDto } from 'api/generated/models';
import Button from 'components/Button';
import PathwayTitle from 'components/PathwayTitle';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import useUserProps from 'hooks/useUserProps';
import { ichraFlowSkeletonRowProps } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import { PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT } from 'pages/dashboard/medicalBenefitSelection/OnOrOffExchangePathwayPlanDetails';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import PlanCardHeader from 'pages/shop/PlanCardHeader';
import { IShoppingPlan } from 'pages/shop/shopping';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const SingleRecommendedPlanContent = ({
    currentTask,
    handleSubmit,
    hhmsCovered,
    individualOnly,
    isPrimaryCovered,
    plan,
}: {
    currentTask: TaskDto;
    handleSubmit: (plan?: IMarketplacePlanDto) => Promise<void>;
    hhmsCovered: string[];
    individualOnly: boolean;
    isPrimaryCovered: boolean;
    plan?: IMarketplacePlanDto;
}) => {
    const {
        ancillaryBenefits,
        hasFetchedHousehold,
        isCompletingTask,
        isLoadingPathway,
        isLoadingPlan,
        isLoadingVisibleFlows,
    } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        hasFetchedHousehold: hasCompletedRequest(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        isCompletingTask: hasApiActivity(state, COMPLETE_TASK_ACTION),
        isLoadingPathway: hasApiActivity(
            state,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_PATHWAY_ACTION
        ),
        isLoadingPlan: hasApiActivity(state, GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION),
        isLoadingVisibleFlows: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const { activeWageUp } = useUserProps();
    const isLoading =
        isLoadingPathway || isLoadingPlan || !hasFetchedHousehold || isLoadingVisibleFlows;
    const getHeader = (p: MarketplacePlanDto) => (
        <PlanCardHeader
            carrier={p?.issuer?.name}
            cost={p?.premium}
            costWithCredit={p?.premium}
            dataCyPrefix="pathway"
            hidePlanCost
            hideYourCost
            name={p?.name}
            plan={{ ...p, isMarketplace: true } as IShoppingPlan}
            showTaxSavings={false}
        />
    );
    const memberIchraCost = plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost;
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <PathwayTitle showMessage />
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="body2">
                    Based on your household information, this pathway would be a great fit for you.
                </Typography>
            </Grid>
            <Grid item width="100%" xs={12}>
                <Skeleton
                    height={320}
                    isEnabled={isLoading}
                    rowProps={ichraFlowSkeletonRowProps}
                    width="100%"
                >
                    <MarketplacePlanCard
                        hideCost
                        hideSelectPlan
                        householdMembersCovered={hhmsCovered}
                        individualOnly={individualOnly}
                        isPrimaryCovered={isPrimaryCovered}
                        key={plan?.id}
                        pathwayPlanCardHeader={
                            hasValue(plan) ? getHeader(plan as MarketplacePlanDto) : undefined
                        }
                        plan={
                            {
                                ...plan,
                                isMarketplace: true,
                            } as IShoppingPlan
                        }
                    />
                </Skeleton>
            </Grid>
            <Skeleton height={35} isEnabled={isLoading} width={460}>
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="h3">
                        Your Major Medical Cost:{' '}
                        {formatCurrency(memberIchraCost, { preserveDecimal: true })} per month
                        <ReimbursementMyCostTooltip
                            memberCost={memberIchraCost}
                            planCost={plan?.premium}
                            reimbursement={activeWageUp?.reimbursement}
                        />
                    </Typography>
                </Grid>
            </Skeleton>
            <Grid item width="100%" xs={12}>
                <Divider />
            </Grid>
            <Grid container item paddingX={{ md: 10, xs: 3 }} paddingY={1} xs={12}>
                <Typography fontSize="0.75rem" variant="body2">
                    {PRESCRIPTIONS_PROVIDERS_DISCLAIMER_TEXT}
                </Typography>
            </Grid>
            <React.Fragment>
                <Grid
                    alignItems="center"
                    container
                    direction={{ xl: 'row-reverse', xs: 'column' }}
                    item
                    justifyContent={{ sm: 'space-between', xs: 'center' }}
                    paddingX={10}
                    paddingY={2}
                    rowGap={2}
                    xs={12}
                >
                    <Skeleton count={2} height={45} isEnabled={isLoading} width={120}>
                        <Button
                            color="secondary"
                            disabled={isLoadingPlan}
                            isLoading={isCompletingTask}
                            onClick={async () => handleSubmit()}
                            sx={{ alignItems: 'center' }}
                            variant="outlined"
                        >
                            See all plans <ArrowForward sx={{ fontSize: '0.9rem' }} />
                        </Button>

                        <Button
                            disabled={isLoadingPlan}
                            isLoading={isCompletingTask}
                            onClick={async () => handleSubmit(plan)}
                            sx={{ paddingInline: 3 }}
                            variant="contained"
                        >
                            Select Recommended Plan
                        </Button>
                    </Skeleton>
                    <IchraFlowButtons
                        currentTaskId={currentTask.globalId}
                        displayAsRow
                        hideContinueButton
                        isLoading={isLoading}
                        redirectToDashboard={redirectToDashboard}
                        showWaiveButton
                    />
                </Grid>
                <Grid item width="100%" xs={12}>
                    <Divider />
                </Grid>
            </React.Fragment>
        </React.Fragment>
    );
};

export default hot(module)(SingleRecommendedPlanContent);
