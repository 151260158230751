import { Divider, Grid, Typography } from '@mui/material';
import { getLatestYearForRates } from 'actions/marketplacePlan/getLatestYearForRates';
import {
    GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
    getSelectedPlansForRenewal,
} from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { EnrollmentStatuses, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlansDto, TaskDto } from 'api/generated/models';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Skeleton from 'components/Skeleton';
import HelpContact from 'components/helpComponents/HelpContact';
import MajorMedicalBenefitCost from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCost';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { some } from 'lodash';
import CustomMaxReimbursementContent from 'pages/dashboard/ichraTaskFlow/CustomMaxReimbursementContent';
import {
    MAX_WIDTH,
    convertToPlanInfo,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import KeepOrChangeBenefitCard from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitCard';
import { ADVISEMENT_SCHEDULED_MESSAGE } from 'pages/shop/ShopHeader';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const KeepOrChangePage = ({ currentTask }: { currentTask: TaskDto }) => {
    const { isLoading, selectedPlans, ancillaryBenefits } = useSelector((state: AppStore) => ({
        ancillaryBenefits: state.ancillaryBenefits,
        isLoading: hasApiActivity(
            state,
            GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
            LIST_VISIBLE_FLOWS_ACTION
        ),
        selectedPlans: state.selectedPlansForRenewal,
    }));
    const dispatch = useThunkDispatch();
    const { activeWageUp, userId, user, yearlyUserInfo } = useUserProps();
    const [chunk, setChunk] = useState<ISelectedPlansDto | undefined>();
    const redirectToDashboard = !arrayHasValue(ancillaryBenefits);

    const isCustomPlan =
        selectedPlans?.effectiveMajorMedicalSelectedPlans?.some((plan) =>
            [PlanTypeIds.CustomOffExchange, PlanTypeIds.CustomStateBasedExchange].includes(
                plan.planTypeId
            )
        ) ?? false;
    const hasIneligiblePlan = selectedPlans.comparedMarketplaceAndOffExchangeSelectedPlans?.some(
        (plan) => hasValue(plan.ineligibleToKeepReason)
    );
    const shouldDisableKeep = hasIneligiblePlan;

    const ifAnyChanges = some(selectedPlans.comparedMarketplaceAndOffExchangeSelectedPlans, (x) =>
        arrayHasValue(x.comparisons)
    );

    const handleChange = () => {
        dispatch(completeTask(currentTask.globalId, { isKeep: false } as ISelectedPlansDto));
    };

    const handleKeep = () => {
        dispatch(
            completeTask(currentTask.globalId, { ...chunk, isKeep: true } as ISelectedPlansDto)
        );
    };

    useEffect(() => {
        const planInfos =
            selectedPlans?.comparedMarketplaceAndOffExchangeSelectedPlans?.map((sp) =>
                convertToPlanInfo(
                    sp.householdMembersCovered,
                    sp.isPrimaryCovered,
                    undefined,
                    sp,
                    sp.year
                )
            ) ?? [];
        selectedPlans?.effectiveMajorMedicalSelectedPlans?.forEach((p) => {
            const planInfo = convertToPlanInfo(
                p.householdMembersCovered,
                p.isPrimaryCovered,
                undefined,
                p,
                p.year
            );
            planInfos.push(planInfo);
        });
        setChunk({ isKeep: false, isWaived: false, planInfoDtos: planInfos } as ISelectedPlansDto);
    }, [selectedPlans]);

    useEffect(() => {
        const year = user?.activeDate?.getYear();
        if (hasValue(year)) {
            dispatch(getSelectedPlansForRenewal(userId, year));
        }
    }, [dispatch, user?.activeDate, userId]);

    useEffect(() => {
        dispatch(getLatestYearForRates());
    }, [dispatch]);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            item
            maxWidth={MAX_WIDTH}
            rowGap={2}
            width="100%"
        >
            <Grid item justifyContent="center" marginBottom={2} xs={12}>
                <Typography textAlign="center" variant="h3">
                    Keep or Change Benefits
                </Typography>

                <Typography textAlign="center" variant="body2">
                    Do you want to keep the plan(s) you are on now for next year?{' '}
                    {ifAnyChanges &&
                        `Here's
                        how your plans will change for ${user?.activeDate?.getYear()}`}
                </Typography>
            </Grid>
            <Grid item width="100%">
                <Skeleton
                    isEnabled={isLoading}
                    rowProps={ichraFlowSkeletonRowProps}
                    structure={[
                        [
                            {
                                columnProps: { flex: 1 },
                                count: 1,
                                height: 290,
                                sx: { mb: 3 },
                                width: '100%',
                            },
                        ],
                        [
                            {
                                columnProps: { flex: 1 },
                                count: 1,
                                height: 80,
                                sx: { mb: 3 },
                                width: '100%',
                            },
                        ],
                        [
                            {
                                columnProps: { alignItems: 'center' },
                                count: 1,
                                height: 45,
                                sx: { mb: 3 },
                                width: 150,
                            },
                            {
                                columnProps: { alignItems: 'center' },
                                count: 1,
                                height: 45,
                                sx: { mb: 3 },
                                width: 150,
                            },
                        ],
                    ]}
                >
                    {selectedPlans?.comparedMarketplaceAndOffExchangeSelectedPlans?.map((sp) => (
                        <Grid item key={`${sp.planId}-${sp.planTypeId}`} xs={12}>
                            <KeepOrChangeBenefitCard selectedPlan={sp} />
                        </Grid>
                    ))}
                    {selectedPlans?.effectiveMajorMedicalSelectedPlans?.map((sp) => (
                        <Grid item key={`${sp.planId}-${sp.planTypeId}`} xs={12}>
                            <KeepOrChangeBenefitCard selectedPlan={sp} />
                        </Grid>
                    ))}

                    {!hasIneligiblePlan && !isCustomPlan && (
                        <Grid container item justifyContent="center" xs={12}>
                            <MajorMedicalBenefitCost
                                costText="Your Major Medical Cost"
                                majorMedicalBenefitCost={selectedPlans?.majorMedicalBenefitCost}
                            />
                        </Grid>
                    )}
                    {isCustomPlan && (
                        <CustomMaxReimbursementContent
                            activeReimbursement={activeWageUp?.reimbursement}
                            addVerticalMargin
                        />
                    )}
                    <Grid item marginTop={2} xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container gap={3} item justifyContent="center" marginTop={2} xs={12}>
                        <ConditionalTooltip
                            isDisabled={!shouldDisableKeep}
                            title="You are ineligible for one or more plans due to household changes or plans leaving the market."
                        >
                            <Button
                                disabled={shouldDisableKeep}
                                isLoading={isLoading}
                                onClick={handleKeep}
                                variant="contained"
                            >
                                Keep
                            </Button>
                        </ConditionalTooltip>
                        <Button isLoading={isLoading} onClick={handleChange} variant="outlined">
                            Change
                        </Button>
                        <IchraFlowButtons
                            currentTaskId={currentTask.globalId}
                            hideContinueButton
                            isLoading={isLoading}
                            redirectToDashboard={redirectToDashboard}
                            showWaiveButton
                        />
                    </Grid>
                    <Grid item marginTop={3} xs={12}>
                        {yearlyUserInfo?.enrollmentStatus?.value ===
                        EnrollmentStatuses.AdvisementScheduled ? (
                            <Typography textAlign="center" variant="body2">
                                {ADVISEMENT_SCHEDULED_MESSAGE}
                            </Typography>
                        ) : (
                            <HelpContact isIchraFlow />
                        )}
                    </Grid>
                </Skeleton>
            </Grid>
        </Grid>
    );
};

export default hot(module)(KeepOrChangePage);
