import React from 'react';
import { Stack, Typography } from '@mui/material';
import { hot } from 'react-hot-loader';
import useTeamProps from 'hooks/useTeamProps';
import { IIchraEnrollmentTaskFlowContent, MAX_WIDTH } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';

const WelcomeToRenewalRemodelHealthPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const { teamName } = useTeamProps();

    return (
        <Stack gap={3} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title="Welcome Back!" />
            <Typography>
                {teamName} is continuing its partnership with Remodel Health to help facilitate your health benefits!
                <br /><br />
                By now you should have received an official ICHRA (Individual Health Coverage Reimbursement Arrangement) notice from your employer that has information about your benefit offering for next year. If you have not received this, please reach out to your HR Administrator.
                <br /><br />
                <strong>What’s next?</strong>
                <br />
                You’ll verify your household information, make any necessary updates, and see your maximum reimbursement amount for next year.
                <br /><br />
                Then you’ll be able to make your plan selections for next year, or choose to waive the benefits that are offered.
            </Typography>
            <IchraFlowButtons currentTaskId={currentTask.globalId} />
        </Stack>
    );
};

export default hot(module)(WelcomeToRenewalRemodelHealthPage);
