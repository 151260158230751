import { Grid } from '@mui/material';
import { clearCounties, clearGetCountiesError, clearMarketplacePlans } from 'actions/clear';
import { GET_COUNTIES_ACTION, getCounties } from 'actions/marketplacePlan/getCounties';
import {
    SEARCH_PLANS_ACTION,
    searchMarketplacePlans,
} from 'actions/marketplacePlan/searchMarketplacePlans';
import { PlanListing } from 'api/generated/enums';
import { IIchraClass } from 'api/generated/models';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import { setPathwayInputs } from 'pages/pathwayBlueprint/pathwayModalActions';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { uniqueMarketplaceCarriersSelector } from 'selectors/marketplace';
import { arrayHasValue, enumToNameValueArray, hasValue, isTrue } from 'utilities';
import { getPlansByCarrier } from 'utilities/marketplacePlan';

export const PLAN_LISTING_ITEMS = enumToNameValueArray(PlanListing, {
    formatName: startCase,
    nameMap: {
        OffExchange: 'Off-Exchange',
        OnExchange: 'On-Exchange',
    },
});

const ZIP_CODE_LENGTH = 5;

type IMarketPlacePathwayInputs = {
    ichraClass?: IIchraClass;
    isAgeBandedIchraPathway: boolean;
    isCappedIchraPathway: boolean;
};
const MarketplacePathwayInputs = ({
    ichraClass,
    isAgeBandedIchraPathway,
    isCappedIchraPathway,
}: IMarketPlacePathwayInputs) => {
    const dispatch = useThunkDispatch();
    const {
        carriers,
        counties,
        getCountyError,
        isLoadingCounties,
        isLoadingMarketplacePlans,
        latestYearForRates,
        marketplacePlans,
        pathwayBlueprint,
        pathwayInputs,
    } = useSelector((state: AppStore) => ({
        carriers: uniqueMarketplaceCarriersSelector(state),
        counties: state.counties,
        getCountyError: !!state.apiErrors[GET_COUNTIES_ACTION.statusCode],
        isLoadingCounties: hasApiActivity(state, GET_COUNTIES_ACTION),
        isLoadingMarketplacePlans: hasApiActivity(state, SEARCH_PLANS_ACTION),
        latestYearForRates: state.marketplaceConfig.latestYearForRates,
        marketplacePlans: state.marketplacePlans,
        pathwayBlueprint: state.pathwayBlueprint,
        pathwayInputs: state.pathwayModalState.pathwayInputs,
    }));
    const { county, errors, ichraBenchmarkType, marketplaceCarrier, marketplacePlanId, zipCode } =
        pathwayInputs ?? {};
    const activeBenchmarkType = ichraClass?.benchmarkPlanType?.toString() ?? ichraBenchmarkType;
    const activeCarrier = ichraClass?.benchmarkCarrier ?? marketplaceCarrier;
    const activeCounty = ichraClass?.county ?? county;
    const activePlan = ichraClass?.benchmarkPlanId ?? marketplacePlanId;
    const activeZipCode = ichraClass?.zipCode ?? zipCode;
    const isValidZipCode = activeZipCode?.length === ZIP_CODE_LENGTH;
    const hasDataToSearchPlans =
        isValidZipCode && hasValue(activeCounty) && hasValue(activeBenchmarkType);
    const marketplaceYear = pathwayBlueprint.isProjection
        ? pathwayBlueprint.year - 1
        : pathwayBlueprint.year;
    const isIchraPathway = isAgeBandedIchraPathway || isCappedIchraPathway;

    const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { name, value } }) => {
        if (name === 'ichraBenchmarkType') {
            dispatch(
                setPathwayInputs({
                    ichraBenchmarkType: value,
                    marketplaceCarrier: '',
                    marketplacePlanId: '',
                })
            );
        } else if (name === 'marketplaceCarrier') {
            dispatch(setPathwayInputs({ marketplaceCarrier: value, marketplacePlanId: '' }));
        } else {
            dispatch(setPathwayInputs({ [name]: value }));
        }
    };
    const onZipCodeChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        dispatch(
            setPathwayInputs({
                county: '',
                marketplaceCarrier: '',
                marketplacePlanId: '',
                zipCode: value,
            })
        );
    };

    const plansByCarrier = useMemo(() => getPlansByCarrier(marketplacePlans, activeCarrier), [
        activeCarrier,
        marketplacePlans,
    ]);

    const benchmarkPlanContent = (
        <React.Fragment>
            <Grid item xs={12}>
                <Select
                    data-cy="benchmark-carrier"
                    defaultText="Choose a Carrier"
                    defaultValue=""
                    errors={errors?.marketplaceCarrier}
                    items={carriers}
                    label="Benchmark Carrier"
                    name="marketplaceCarrier"
                    onChange={onChange}
                    optionText="name"
                    optionValue="name"
                    value={activeCarrier}
                />
            </Grid>
            <Grid item xs={12}>
                <Select
                    data-cy="benchmark-plan"
                    defaultText="Choose a Plan"
                    defaultValue=""
                    errors={errors?.marketplacePlanId}
                    items={plansByCarrier}
                    label="Benchmark Plan"
                    name="marketplacePlanId"
                    onChange={onChange}
                    optionText="name"
                    optionValue="id"
                    value={activePlan}
                />
            </Grid>
        </React.Fragment>
    );
    useEffect(() => {
        dispatch(clearMarketplacePlans());
        if (hasDataToSearchPlans && arrayHasValue(counties) && !isLoadingMarketplacePlans) {
            const state = counties.find((x) => x.fips === activeCounty)?.stateAbbrev;
            dispatch(
                searchMarketplacePlans(
                    state,
                    activeZipCode,
                    activeCounty,
                    marketplaceYear,
                    PlanListing[activeBenchmarkType as keyof typeof PlanListing]
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        counties,
        activeCounty,
        dispatch,
        hasDataToSearchPlans,
        activeBenchmarkType,
        latestYearForRates,
        marketplaceYear,
        activeZipCode,
    ]);
    useEffect(() => {
        if (isValidZipCode) {
            dispatch(getCounties(activeZipCode, marketplaceYear));
        }
    }, [dispatch, isValidZipCode, activeZipCode, marketplaceYear]);
    useEffect(() => {
        if (isTrue(getCountyError)) {
            dispatch(setPathwayInputs({ zipCode: '' }));
            dispatch(clearGetCountiesError());
        }
        return () => {
            dispatch(clearCounties());
        };
    }, [dispatch, getCountyError]);

    useEffect(() => {
        if (isIchraPathway && !hasValue(ichraBenchmarkType) && !hasValue(ichraClass)) {
            dispatch(setPathwayInputs({ ichraBenchmarkType: PlanListing.OffExchange.toString() }));
        } else if (!isIchraPathway && !hasValue(ichraClass)) {
            dispatch(setPathwayInputs({ ichraBenchmarkType: PlanListing.OnExchange.toString() }));
        }
    }, [dispatch, ichraBenchmarkType, ichraClass, isIchraPathway]);

    return (
        <Grid columnSpacing={2} container rowGap={2}>
            {(hasValue(ichraClass) || isIchraPathway) && (
                <Grid item xs={12}>
                    <Select
                        data-cy="ichra-benchmark-type"
                        defaultText="Choose an ICHRA Benchmark Type"
                        defaultValue=""
                        errors={errors?.ichraBenchmarkType}
                        items={PLAN_LISTING_ITEMS}
                        label="ICHRA Benchmark Plan Type"
                        name="ichraBenchmarkType"
                        onChange={onChange}
                        optionText="name"
                        optionValue="value"
                        value={activeBenchmarkType}
                    />
                </Grid>
            )}
            <Grid item md={6} xs={12}>
                <TextField
                    errors={errors?.zipCode}
                    label="Zip Code"
                    name="zipCode"
                    onChange={onZipCodeChange}
                    placeholder="Enter Zip Code"
                    value={activeZipCode}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Skeleton count={1} height="56px" isEnabled={isLoadingCounties}>
                    <Select
                        defaultText="Choose a County"
                        defaultValue=""
                        errors={errors?.county}
                        items={counties}
                        label="County"
                        name="county"
                        onChange={onChange}
                        optionText="name"
                        optionValue="fips"
                        value={activeCounty}
                    />
                </Skeleton>
            </Grid>
            <Grid item xs={12}>
                <Skeleton
                    count={2}
                    height="56px"
                    isEnabled={isLoadingMarketplacePlans}
                    structure={[
                        [
                            {
                                columnProps: { flex: 1, gap: 2 },
                                count: 2,
                                height: 56,
                            },
                        ],
                    ]}
                >
                    <Grid container item rowGap={2} xs={12}>
                        {hasDataToSearchPlans && benchmarkPlanContent}
                    </Grid>
                </Skeleton>
            </Grid>
        </Grid>
    );
};

export default hot(module)(MarketplacePathwayInputs);
