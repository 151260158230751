import { PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

export const getAreMarketplaceRatesAvailable = (state: AppStore, year: number | undefined) =>
    year === undefined || state.marketplaceConfig.latestYearForRates === undefined
        ? false
        : year <= state.marketplaceConfig.latestYearForRates;

export const getShouldHideCosts = (state: AppStore, planId?: string) => {
    let effectiveMajorMedicalPlans =
        state.selectedPlansForRenewal?.effectiveMajorMedicalSelectedPlans ?? [];
    if (hasValue(planId)) {
        effectiveMajorMedicalPlans = effectiveMajorMedicalPlans?.filter(
            (plan) => plan.planId === planId
        );
    }
    return effectiveMajorMedicalPlans.some(
        (x) =>
            x.planStateId === PlanStateIds.Effective &&
            [
                PlanTypeIds.CustomStateBasedExchange,
                PlanTypeIds.CustomOffExchange,
                PlanTypeIds.CustomMajorMedical,
            ].includes(x.planTypeId)
    );
};
