import { Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import IchraMediShareMyCostTooltip from 'components/IchraMediShareMyCostTooltip';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import { HEADER_FONT_COLOR } from 'constants/styleConstants';
import useUserProps from 'hooks/useUserProps';
import HsaEligibleChip from 'pages/shop/HsaEligibleChip';
import { getDataCyPathwayPlanPrefix } from 'pages/shop/PlanCardBase';
import RecommendedPlanBookmark from 'pages/shop/RecommendedPlanBookmark';
import { IShoppingPlan } from 'pages/shop/shopping';
import TaxSavingsChip from 'pages/shop/TaxSavingsChip';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { getCanShopForMember, getReimbursement } from 'selectors/shopping';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';
import { getCostLabel } from 'utilities/selectedPlan';

const getWasText = (isInIchraPathway: boolean, cost?: number, costWithCredit?: number) => {
    if (!isInIchraPathway && cost !== costWithCredit) {
        return (
            <SmallText as={Row} className="justify-content-end px-2">
                <SmallText className="text-muted mr-1">Was:</SmallText>
                <s className="text-danger">${cost} / mo</s>
            </SmallText>
        );
    }
    return '';
};

export type IPlanCardHeaderProps = {
    carrier?: string;
    cost?: number;
    costWithCredit?: number;
    dataCyPrefix?: string;
    hidePlanCost?: boolean;
    hidePreviouslySelected?: boolean;
    hideYourCost?: boolean;
    isChunkPlan?: boolean;
    isCustomPlan?: boolean;
    isFlowSummary?: boolean;
    isMedicare?: boolean;
    majorMedicalBenefitCostDto?: IMajorMedicalBenefitCostDto | null;
    name?: string;
    plan: Partial<IShoppingPlan>;
    recommendedPlanDescription?: string;
    recommendedPlanLabel?: string;
    reimbursementAmount?: number;
    showRecommendedPlanBookmark?: boolean;
    showTaxSavings?: boolean;
};
const PlanCardHeader = ({
    carrier,
    cost,
    costWithCredit,
    dataCyPrefix,
    isMedicare = false,
    hidePlanCost = false,
    hidePreviouslySelected = false,
    hideYourCost,
    isChunkPlan,
    isCustomPlan = false,
    isFlowSummary,
    name,
    plan,
    recommendedPlanDescription,
    recommendedPlanLabel,
    reimbursementAmount,
    showRecommendedPlanBookmark = false,
    showTaxSavings = false,
}: IPlanCardHeaderProps) => {
    const { isCurrent } = useUserProps();
    const { canShopForMember, pathwayUser, reimbursement } = useSelector((state: AppStore) => ({
        canShopForMember: getCanShopForMember(state),
        pathwayUser: state.pathwayUser,
        reimbursement: getReimbursement(state, isCurrent),
    }));
    const isInIchraPathway = isIchraPathwayType(pathwayUser?.pathwayType);
    let formattedPlanCost = formatCurrency(costWithCredit, { includeDollarSign: false });
    let yourCost = isMedicare
        ? cost
        : Math.round(Number(plan?.majorMedicalBenefitCost?.preTaxTotalMajorMedicalBenefitCost));
    let myCostContent = (
        <MajorMedicalBenefitCostButton majorMedicalBenefitCost={plan?.majorMedicalBenefitCost}>
            Your Cost
        </MajorMedicalBenefitCostButton>
    );

    if (isChunkPlan || isInIchraPathway) {
        formattedPlanCost = formatCurrency(cost, {
            includeDollarSign: false,
            preserveDecimal: true,
        });
        if (plan?.isMediShare) {
            myCostContent = (
                <React.Fragment>
                    <span>Your Cost</span>
                    <IchraMediShareMyCostTooltip planCost={cost} />
                </React.Fragment>
            );
        } else {
            yourCost = isFlowSummary
                ? (plan?.majorMedicalBenefitCost?.postTaxTotalPlanCost as number)
                : plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost ?? 0;
            myCostContent = (
                <React.Fragment>
                    <span>{isFlowSummary ? 'Plan Cost' : 'Your Cost'}</span>
                    {!isFlowSummary && (
                        <ReimbursementMyCostTooltip
                            memberCost={yourCost}
                            planCost={cost}
                            reimbursement={reimbursementAmount ?? reimbursement}
                        />
                    )}
                </React.Fragment>
            );
        }
    }

    const wasContent = getWasText(isInIchraPathway, cost, costWithCredit);
    const hasNonCustomSummaryCost = isFlowSummary && hasValue(cost) && !isCustomPlan;
    const shouldShowCost =
        (canShopForMember && !hidePlanCost) || hasNonCustomSummaryCost || isMedicare;

    return (
        <Grid container direction="column" rowGap={2}>
            <Grid
                alignItems="center"
                className="text-center"
                container
                flexWrap={{ md: 'nowrap', xs: 'wrap' }}
                item
                justifyContent="space-between"
                spacing={2}
            >
                <Grid className="text-left" item md xs={12}>
                    {showRecommendedPlanBookmark && (
                        <RecommendedPlanBookmark
                            description={recommendedPlanDescription}
                            label={recommendedPlanLabel}
                        />
                    )}
                    {hasValue(carrier) && <SmallText>{carrier}</SmallText>}
                    <Typography color={HEADER_FONT_COLOR} marginTop={0} variant="h3">
                        {name}{' '}
                        {isChunkPlan && !hidePreviouslySelected && (
                            <Chip
                                color="default"
                                label={<span className="font-italic">Previously Selected</span>}
                                size="medium"
                            />
                        )}
                    </Typography>
                    <Grid container item spacing={0.5} xs={3}>
                        {showTaxSavings && (
                            <Grid item xs={12}>
                                <TaxSavingsChip />
                            </Grid>
                        )}
                        {!isMedicare && (
                            <Grid item xs={12}>
                                <HsaEligibleChip isHsaEligible={plan?.isHsaEligible ?? false} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    alignItems="center"
                    container
                    item
                    justifyContent="flex-end"
                    md="auto"
                    xs={12}
                >
                    {shouldShowCost && (
                        <div className="text-center mx-4 mb-1">
                            {!isFlowSummary && wasContent}
                            <SmallText
                                as={Row}
                                className="justify-content-end px-2"
                                data-cy={`${getDataCyPathwayPlanPrefix(dataCyPrefix)}plan-cost`}
                            >
                                <SmallText className="text-muted mr-1">
                                    {getCostLabel(plan?.isMediShare)}:
                                </SmallText>
                                <BlackText>${formattedPlanCost} / mo</BlackText>
                            </SmallText>
                        </div>
                    )}
                    {!hideYourCost && (
                        <div
                            className="text-center"
                            data-cy={`your-${getDataCyPathwayPlanPrefix(dataCyPrefix)}cost`}
                        >
                            {!isFlowSummary ? (
                                <React.Fragment>
                                    <MyCostText>{myCostContent}</MyCostText>
                                    <MyCostAmount>
                                        <DollarText>$</DollarText>
                                        <span>
                                            {isInIchraPathway
                                                ? formatCurrency(yourCost, {
                                                      includeDollarSign: false,
                                                      preserveDecimal: true,
                                                  })
                                                : yourCost}
                                        </span>
                                        <SmallText>/ mo</SmallText>
                                    </MyCostAmount>
                                </React.Fragment>
                            ) : (
                                <DollarText>
                                    {myCostContent}: <b>${yourCost}/ mo</b>
                                </DollarText>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
            {isMedicare && (
                <React.Fragment>
                    <Grid
                        container
                        flexWrap={{ md: 'nowrap', xs: 'wrap' }}
                        item
                        justifyContent="flex-end"
                        paddingRight={3}
                    >
                        <SmallText>
                            This is the monthly premium we currently have on file for your Medicare
                            plan.
                        </SmallText>
                    </Grid>
                    <Grid
                        container
                        flexWrap={{ md: 'nowrap', xs: 'wrap' }}
                        item
                        justifyContent="flex-end"
                        paddingRight={3}
                    >
                        <SmallText>
                            If your monthly premium has changed, please reach out to
                            medicare@remodelhealth.com
                        </SmallText>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

const BlackText = styled.span`
    color: black;
`;

const DollarText = styled.sup`
    font-size: 16px;
`;

const SmallText = styled.span`
    font-size: 12px;
`;

const MyCostText = styled.div.attrs(() => ({
    className: 'm-0 p-0',
}))`
    font-weight: 600;
`;
const MyCostAmount = styled.p.attrs(() => ({
    className: 'm-0 p-0 text-primary',
}))`
    font-size: 30px;
    font-weight: 600;
`;

export default hot(module)(PlanCardHeader);
