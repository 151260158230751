import Stack from '@mui/material/Stack';
import { ADD_PATHWAY_ACTION } from 'actions/pathwayBlueprint/addPathway';
import { EDIT_PATHWAY_ACTION } from 'actions/pathwayBlueprint/editPathway';
import { PathwayInputTypes, PathwayTypes, PlanListing } from 'api/generated/enums';
import { IPathway, MarketplacePlanDto } from 'api/generated/models';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import NumberTextField from 'components/NumberTextField';
import PercentTextField from 'components/PercentTextField';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import TextField from 'components/TextField';
import AgeBandCoverageLevelInputs from 'components/pathwayModal/AgeBandCoverageLevelInputs';
import CalculatedAgeBandsTable from 'components/pathwayModal/CalculatedAgeBandsTable';
import { ahpOptions } from 'constants/ahpOptions';
import { PATHWAY_TYPE_ITEMS } from 'constants/pathways';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { sortBy } from 'lodash';
import startCase from 'lodash/startCase';
import IchraRecommendedPlanInputs from 'pages/pathwayBlueprint/IchraRecommendedPlanInputs';
import MarketplacePathwayInputs from 'pages/pathwayBlueprint/MarketplacePathwayInputs';
import {
    clearPathwayInputs,
    savePathway,
    setPathwayInputs,
    setPathwayModalProps,
} from 'pages/pathwayBlueprint/pathwayModalActions';
import {
    DISPLAY_NAME_OVERRIDE_TEXT_MAX_LENGTH,
    EXTRA_PATHWAY_BENEFIT_TEXT_MAX_LENGTH,
} from 'pages/pathwayBlueprint/pathwayModalState';
import React, { useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, enumToNameValueArray, hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getPlan } from 'utilities/marketplacePlan';
import {
    getIsPathwayOfType,
    getPathwayInput,
    isCappedCostIchraPathwayType,
    isCappedWageUpPathwayType,
    isEmployeeCappedPathwayType,
    isExchangeBenchmarkPathwayType,
    isHealthcareSharingPathwayType,
    isIchraExchangeBenchmarkPathwayType,
    requiresCoverageLevelInputs,
    requiresPlan,
} from 'utilities/pathways';

const PER_MEMBER_MEDICARE_COST_ESTIMATE_DEFAULT = '270';
const DEPENDENT_KICKER_THRESHOLD_MAX = 10;
export const REIMBURSEMENT_RATIO_PREMIUM_DEFAULT = '3';

export const getAdditionalDependentKickerHelperText = (dependentKickerThreshold: number | string) =>
    `This is the amount of reimbursement that will be added on top for each dependent beyond the first ${
        hasValue(dependentKickerThreshold) && Number(dependentKickerThreshold) !== 1
            ? `${dependentKickerThreshold} dependents`
            : ''
    } in a household.`;

enum CapType {
    DollarAmount,
    Percentage,
}

const CAP_TYPE_ITEMS = enumToNameValueArray(CapType, {
    formatName: startCase,
});

const AGE_BAND_SIZES = [
    { text: '1-Year', value: '1' },
    { text: '5-Years', value: '5' },
    { text: '10-Years', value: '10' },
];

const getModalTitle = (pathway: IPathway | undefined) => (pathway?.id ? 'Edit' : 'Add');
const getCoverageLevelCapsLabel = (pathwayType: string) => {
    if (getIsPathwayOfType(pathwayType, PathwayTypes.Spouse, PathwayTypes.Parent)) {
        return 'Estimates';
    } else if (
        getIsPathwayOfType(
            pathwayType,
            PathwayTypes.Flat,
            PathwayTypes.IchraFlat,
            PathwayTypes.IchraMedicare
        )
    ) {
        return 'Contributions';
    }
    return 'Caps';
};

const getCoverageLevelInput = (capType: string) =>
    Number(capType) === CapType.Percentage ? PercentTextField : CurrencyTextField;

const PathwayModal = () => {
    const dispatch = useThunkDispatch();
    const {
        address,
        errors,
        isLoading,
        pathway,
        pathwayBlueprint,
        pathwayInputs,
        recommendedPlans,
    } = useSelector((state: AppStore) => ({
        address: state.teamProfile?.address,
        errors: state.pathwayModalState.pathwayInputs?.errors,
        isLoading: hasApiActivity(state, ADD_PATHWAY_ACTION, EDIT_PATHWAY_ACTION),
        pathway: state.pathwayModalState.selectedItem,
        pathwayBlueprint: state.pathwayBlueprint,
        pathwayInputs: state.pathwayModalState.pathwayInputs,
        recommendedPlans: state.recommendedPlans,
    }));

    const [pathwayName, setPathwayName] = useState(pathway?.name ?? '');
    const [pathwayType, setPathwayType] = useState(pathway?.pathwayTypeId?.toString() ?? '');
    const [useRestrategizedRenewalFlow, setUseRestrategizedRenewalFlow] = useState(
        getPathwayInput(pathway, PathwayInputTypes.UseRestrategizedRenewalFlow, 'false')
    );
    const [capType, setCapType] = useState(
        getPathwayInput(pathway, PathwayInputTypes.CapType, `${CapType.DollarAmount}`)
    );
    const [ee, setEe] = useState(getPathwayInput(pathway, PathwayInputTypes.CoverageLevelEE));
    const [es, setEs] = useState(getPathwayInput(pathway, PathwayInputTypes.CoverageLevelES));
    const [ec, setEc] = useState(getPathwayInput(pathway, PathwayInputTypes.CoverageLevelEC));
    const [ef, setEf] = useState(getPathwayInput(pathway, PathwayInputTypes.CoverageLevelEF));
    const [ahpOption, setAhpOption] = useState(
        getPathwayInput(pathway, PathwayInputTypes.MediShareBenchmarkProgram)
    );
    const [wageUpCap, setWageUpCap] = useState(
        getPathwayInput(pathway, PathwayInputTypes.WageUpCap)
    );
    const [chipEstimate, setChipEstimate] = useState(
        getPathwayInput(pathway, PathwayInputTypes.ChipEstimate)
    );
    const [perMemberMedicareCostEstimate, setPerMemberMedicareCostEstimate] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.PerMemberMedicareCostEstimate,
            getIsPathwayOfType(pathwayType, PathwayTypes.Medicare, PathwayTypes.IchraMedicare)
                ? PER_MEMBER_MEDICARE_COST_ESTIMATE_DEFAULT
                : ''
        )
    );
    const [startingWageUp, setStartingWageUp] = useState(
        getPathwayInput(pathway, PathwayInputTypes.StartingWageUp)
    );
    const [automaticApprovalCap, setAutomaticApprovalCap] = useState(
        getPathwayInput(pathway, PathwayInputTypes.AutomaticApprovalCap)
    );
    const [waivedContribution, setWaivedContribution] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.WaivedContribution,
            pathwayBlueprint.wageUpFloor.toString()
        )
    );
    const [medicaidEstimate, setMedicaidEstimate] = useState(
        getPathwayInput(pathway, PathwayInputTypes.MedicaidEstimate, '0')
    );
    const [extraBenefitText, setExtraBenefitText] = useState(
        getPathwayInput(pathway, PathwayInputTypes.ExtraBenefitText)
    );
    const [ichraClassId, setIchraClassId] = useState(pathway?.ichraClassId?.toString());
    const [ageBandSize, setAgeBandSize] = useState(
        getPathwayInput(pathway, PathwayInputTypes.AgeBandSize, '5')
    );
    const [sixtyFourEe, setSixtyFourEe] = useState(
        getPathwayInput(pathway, PathwayInputTypes.SixtyFourYearOldEE)
    );
    const [sixtyFourEs, setSixtyFourEs] = useState(
        getPathwayInput(pathway, PathwayInputTypes.SixtyFourYearOldES)
    );
    const [sixtyFourEc, setSixtyFourEc] = useState(
        getPathwayInput(pathway, PathwayInputTypes.SixtyFourYearOldEC)
    );
    const [sixtyFourEf, setSixtyFourEf] = useState(
        getPathwayInput(pathway, PathwayInputTypes.SixtyFourYearOldEF)
    );
    const [additionalDependentKicker, setAdditionalDependentKicker] = useState(
        getPathwayInput(pathway, PathwayInputTypes.AdditionalDependentKicker)
    );
    const [dependentKickerThreshold, setDependentKickerThreshold] = useState(
        getPathwayInput(pathway, PathwayInputTypes.DependentKickerThreshold, '1')
    );

    const [eeReimbursementRatio, setEeReimbursementRatio] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.EeReimbursementRatio,
            REIMBURSEMENT_RATIO_PREMIUM_DEFAULT
        )
    );
    const [esReimbursementRatio, setEsReimbursementRatio] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.EsReimbursementRatio,
            REIMBURSEMENT_RATIO_PREMIUM_DEFAULT
        )
    );
    const [ecReimbursementRatio, setEcReimbursementRatio] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.EcReimbursementRatio,
            REIMBURSEMENT_RATIO_PREMIUM_DEFAULT
        )
    );
    const [efReimbursementRatio, setEfReimbursementRatio] = useState(
        getPathwayInput(
            pathway,
            PathwayInputTypes.EfReimbursementRatio,
            REIMBURSEMENT_RATIO_PREMIUM_DEFAULT
        )
    );
    const [displayNameOverride, setDisplayNameOverride] = useState(
        getPathwayInput(pathway, PathwayInputTypes.DisplayNameOverride)
    );
    const [employerCoversEeOnly, setEmployeeCoversEeOnly] = useState(
        getPathwayInput(pathway, PathwayInputTypes.EmployerCoversEeOnly, 'false')
    );

    const isCappedWageUpType = isCappedWageUpPathwayType(pathwayType);
    const isEmployeeCappedType = isEmployeeCappedPathwayType(pathwayType);
    const isExchangeBenchmarkType = isExchangeBenchmarkPathwayType(pathwayType);
    const isIchraExchangeBenchmarkType = isIchraExchangeBenchmarkPathwayType(pathwayType);
    const isSharingType = isHealthcareSharingPathwayType(pathwayType);
    const isSpouseOrParentType = getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Spouse,
        PathwayTypes.Parent
    );
    const isWaivedPathwayType = getIsPathwayOfType(pathwayType, PathwayTypes.Waived);
    const isMedicaidPathwayType = getIsPathwayOfType(pathwayType, PathwayTypes.Medicaid);
    const isMedicarePathwayType = getIsPathwayOfType(
        pathwayType,
        PathwayTypes.Medicare,
        PathwayTypes.IchraMedicare
    );
    const isCappedCostIchraType = isCappedCostIchraPathwayType(pathwayType);
    const isAgeBandedIchraType = getIsPathwayOfType(pathwayType, PathwayTypes.IchraAgeBanded);
    const isPlanRequired = requiresPlan(pathwayType);
    const showCoverageCapInputs = requiresCoverageLevelInputs(pathwayType);
    const showUseRestrategizedRenewalFlow = isExchangeBenchmarkType || isSharingType;

    useEffect(() => {
        dispatch(
            setPathwayInputs({
                county: getPathwayInput(pathway, PathwayInputTypes.County, address?.countyFips),
                ichraBenchmarkType: getPathwayInput(pathway, PathwayInputTypes.IchraBenchmarkType),
                marketplaceCarrier: getPathwayInput(pathway, PathwayInputTypes.MarketplaceCarrier),
                marketplacePlanId: getPathwayInput(pathway, PathwayInputTypes.MarketplacePlanId),
                pathwayRecommendedPlans: pathway?.pathwayRecommendedPlans,
                zipCode: getPathwayInput(pathway, PathwayInputTypes.Zipcode, address?.zip),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, dispatch, pathway]);
    useEffect(
        () => () => {
            dispatch(clearPathwayInputs());
        },
        [dispatch]
    );

    const onClose = () =>
        dispatch(setPathwayModalProps({ isModalVisible: false, selectedItem: undefined }));
    const onSubmit = async () => {
        const updatedRecommendedPlan = pathwayInputs?.pathwayRecommendedPlans?.map((prp, index) => {
            const plans =
                Number(prp.benchmarkType) === PlanListing.OnExchange
                    ? recommendedPlans.onExchange
                    : recommendedPlans.offExchange;
            const planDetails =
                index !== 0
                    ? (getPlan(
                          prp.carrierId as string,
                          prp.planId as string,
                          plans
                      ) as MarketplacePlanDto)
                    : prp.planDetails;

            prp.order = index + 1;
            prp.planDetails = planDetails;
            prp.planName = planDetails?.name;

            return prp;
        });

        dispatch(setPathwayInputs({ pathwayRecommendedPlans: updatedRecommendedPlan }));

        const shouldClose = await dispatch(
            savePathway(pathwayBlueprint.id, pathway, {
                additionalDependentKicker,
                ageBandSize,
                ahpOption,
                automaticApprovalCap,
                capType,
                chipEstimate,
                dependentKickerThreshold,
                displayNameOverride,
                ec,
                ecReimbursementRatio,
                ee,
                eeReimbursementRatio,
                ef,
                efReimbursementRatio,
                employerCoversEeOnly,
                es,
                esReimbursementRatio,
                extraBenefitText,
                ichraClassId,
                medicaidEstimate,
                pathwayName,
                pathwayType,
                perMemberMedicareCostEstimate,
                sixtyFourEc,
                sixtyFourEe,
                sixtyFourEf,
                sixtyFourEs,
                startingWageUp,
                useRestrategizedRenewalFlow,
                wageUpCap,
                waivedContribution,
            })
        );
        if (shouldClose) {
            onClose();
        }
    };
    const onPathwayTypeChange = (value: string) => {
        setPathwayType(value);
        if (
            getIsPathwayOfType(
                value,
                PathwayTypes.Medicare,
                PathwayTypes.IchraMedicare,
                PathwayTypes.IchraAgeBanded
            )
        ) {
            setPerMemberMedicareCostEstimate(PER_MEMBER_MEDICARE_COST_ESTIMATE_DEFAULT);
        } else {
            setPerMemberMedicareCostEstimate('');
        }
    };
    const onCapTypeChange = (value: string) => {
        setCapType(value);
        setEe('');
        setEs('');
        setEc('');
        setEf('');
    };

    const titleLabel = getModalTitle(pathway) + ' Pathway';
    const coverageLevelInputsTitle = 'Coverage Level ' + getCoverageLevelCapsLabel(pathwayType);

    const CoverageLevelCapInput = useMemo(() => getCoverageLevelInput(capType), [capType]);
    const perMemberMedicareEstimateField = (
        <CurrencyTextField
            errors={errors?.perMemberMedicareCostEstimate}
            helperText="An estimated cost for Medicare for each member of the household."
            isOptional={!isMedicarePathwayType && !isAgeBandedIchraType}
            label="Per Member Medicare Cost Estimate"
            name="perMemberMedicareCostEstimate"
            onChange={onChange(setPerMemberMedicareCostEstimate)}
            placeholder="Enter Medicare Estimate"
            value={perMemberMedicareCostEstimate}
        />
    );

    useEffect(() => {
        if (
            !getIsPathwayOfType(
                pathwayType,
                PathwayTypes.IchraAgeBanded,
                PathwayTypes.IchraCappedEmployeeCost,
                PathwayTypes.IchraCappedEmployerCost
            ) &&
            arrayHasValue(pathwayInputs?.pathwayRecommendedPlans)
        ) {
            dispatch(setPathwayInputs({ ...pathwayInputs, pathwayRecommendedPlans: undefined }));
        }
    }, [dispatch, pathwayInputs, pathwayType]);

    return (
        <Modal onHide={onClose} scrollable show size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{titleLabel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="pathway-details-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack gap={2}>
                        <TextField
                            autoFocus
                            data-cy="pathway-name"
                            errors={errors?.pathwayName}
                            inputProps={{
                                maxLength: 255,
                            }}
                            label="Pathway Name"
                            name="pathwayName"
                            onChange={onChange(setPathwayName)}
                            placeholder="Enter Pathway Name"
                            value={pathwayName}
                        />
                        <Select
                            data-cy="pathway-type"
                            defaultText="Choose a Type"
                            defaultValue=""
                            errors={errors?.pathwayType}
                            items={PATHWAY_TYPE_ITEMS}
                            label="Pathway Type"
                            name="pathwayType"
                            onChange={onChange(onPathwayTypeChange)}
                            optionText="name"
                            optionValue="value"
                            value={pathwayType}
                        />
                        {isAgeBandedIchraType && arrayHasValue(pathwayBlueprint?.ichraClasses) && (
                            <Select
                                defaultText="Choose an ICHRA Class"
                                defaultValue=""
                                errors={errors?.ichraClassId}
                                items={sortBy(pathwayBlueprint?.ichraClasses, (ic) => ic.name)}
                                label="ICHRA Class"
                                name="ichraClassId"
                                onChange={onChange(setIchraClassId)}
                                optionText="name"
                                optionValue="id"
                                value={ichraClassId}
                            />
                        )}
                        {showUseRestrategizedRenewalFlow && (
                            <Select
                                data-cy="use-restrategized-renewal-flow"
                                defaultText=""
                                defaultValue=""
                                errors={errors?.useRestrategizedRenewalFlow}
                                helperText={
                                    'Setting this to yes will mean that any member in this pathway will see the pathway benefit ' +
                                    'selection task instead of the keep or change benefits task when they go through the renewal process'
                                }
                                items={yesOrNo}
                                label="Use Restrategized Renewal Flow"
                                name="useRestrategizedRenewalFlow"
                                onChange={onChange(setUseRestrategizedRenewalFlow)}
                                optionText="text"
                                optionValue="value"
                                value={useRestrategizedRenewalFlow}
                            />
                        )}
                        {isPlanRequired && (
                            <React.Fragment>
                                {isEmployeeCappedType && (
                                    <Select
                                        defaultText="Choose a Type"
                                        defaultValue=""
                                        errors={errors?.capType}
                                        items={CAP_TYPE_ITEMS}
                                        key="capType"
                                        label="Cap Type"
                                        name="capType"
                                        onChange={onChange(onCapTypeChange)}
                                        optionText="name"
                                        optionValue="value"
                                        value={capType}
                                    />
                                )}
                                {isExchangeBenchmarkType && (
                                    <MarketplacePathwayInputs
                                        isAgeBandedIchraPathway={isAgeBandedIchraType}
                                        isCappedIchraPathway={isCappedCostIchraType}
                                    />
                                )}
                                {isSharingType && (
                                    <Select
                                        data-cy="benchmark-program"
                                        defaultText="Choose a program"
                                        defaultValue=""
                                        errors={errors?.ahpOption}
                                        items={ahpOptions}
                                        key="ahpOption"
                                        label="Benchmark Program"
                                        name="ahpOption"
                                        onChange={onChange(setAhpOption)}
                                        optionText="name"
                                        optionValue="id"
                                        value={ahpOption}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        {isMedicarePathwayType && perMemberMedicareEstimateField}
                        {showCoverageCapInputs && (
                            <React.Fragment>
                                <Col className="p-0">
                                    <label className="mb-2">{coverageLevelInputsTitle}</label>
                                    <Row>
                                        <Col>
                                            <CoverageLevelCapInput
                                                data-cy="ee"
                                                errors={errors?.ee}
                                                label="EE"
                                                name="ee"
                                                onChange={onChange(setEe)}
                                                placeholder="Enter EE"
                                                value={ee}
                                            />
                                        </Col>
                                        <Col>
                                            <CoverageLevelCapInput
                                                data-cy="es"
                                                errors={errors?.es}
                                                label="ES"
                                                name="es"
                                                onChange={onChange(setEs)}
                                                placeholder="Enter ES"
                                                value={es}
                                            />
                                        </Col>
                                        <Col>
                                            <CoverageLevelCapInput
                                                data-cy="ec"
                                                errors={errors?.ec}
                                                label="EC"
                                                name="ec"
                                                onChange={onChange(setEc)}
                                                placeholder="Enter EC"
                                                value={ec}
                                            />
                                        </Col>
                                        <Col>
                                            <CoverageLevelCapInput
                                                data-cy="ef"
                                                errors={errors?.ef}
                                                label="EF"
                                                name="ef"
                                                onChange={onChange(setEf)}
                                                placeholder="Enter EF"
                                                value={ef}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </React.Fragment>
                        )}
                        {isCappedCostIchraType && (
                            <Select
                                data-cy="employer-covers-ee-only"
                                defaultText=""
                                defaultValue=""
                                errors={errors?.employerCoversEeOnly}
                                helperText="If set to yes, the reimbursement will be calculated based on the benchmark plan cost for just the employee on their own."
                                items={yesOrNo}
                                label="Employer Covers EE-Only"
                                name="employerCoversEeOnly"
                                onChange={onChange(setEmployeeCoversEeOnly)}
                                optionText="text"
                                optionValue="value"
                                value={employerCoversEeOnly}
                            />
                        )}
                        {isCappedWageUpType && (
                            <React.Fragment>
                                {isEmployeeCappedType && (
                                    <CurrencyTextField
                                        errors={errors?.wageUpCap}
                                        helperText="Represents the max amount that the employer will give any one employee."
                                        isOptional
                                        label="Wage+ Cap"
                                        name="wageUpCap"
                                        onChange={onChange(setWageUpCap)}
                                        placeholder="Enter Wage+ Cap"
                                        value={wageUpCap}
                                    />
                                )}
                                <CurrencyTextField
                                    errors={errors?.chipEstimate}
                                    helperText="An estimated cost for CHIP for the household that will be added on top of their Marketplace plan cost."
                                    isOptional
                                    label="Chip Estimate"
                                    name="chipEstimate"
                                    onChange={onChange(setChipEstimate)}
                                    placeholder="Enter Chip Estimate"
                                    value={chipEstimate}
                                />
                            </React.Fragment>
                        )}
                        {(isCappedWageUpType || isCappedCostIchraType) &&
                            perMemberMedicareEstimateField}
                        {isSpouseOrParentType && (
                            <React.Fragment>
                                <CurrencyTextField
                                    errors={errors?.startingWageUp}
                                    helperText="The Wage+ that will initially be offered to the member when they go through the enrollment flow."
                                    label="Starting Wage+"
                                    name="startingWageUp"
                                    onChange={onChange(setStartingWageUp)}
                                    placeholder="Enter Starting Wage+"
                                    value={startingWageUp}
                                />
                                <CurrencyTextField
                                    errors={errors?.automaticApprovalCap}
                                    helperText="The dollar amount up to which requests for more Wage+ will be automatically approved."
                                    label="Automatic Approval Cap"
                                    name="automaticApprovalCap"
                                    onChange={onChange(setAutomaticApprovalCap)}
                                    placeholder="Enter Automatic Approval Cap"
                                    value={automaticApprovalCap}
                                />
                            </React.Fragment>
                        )}
                        {isWaivedPathwayType && (
                            <CurrencyTextField
                                decimalScale={0}
                                errors={errors?.waivedContribution}
                                helperText="The amount the employer is going to give to waived employees."
                                label="Waived Contribution"
                                name="waivedContribution"
                                onChange={onChange(setWaivedContribution)}
                                placeholder="Enter Waived Contribution"
                                value={waivedContribution}
                            />
                        )}
                        {isMedicaidPathwayType && (
                            <CurrencyTextField
                                decimalScale={0}
                                errors={errors?.medicaidEstimate}
                                helperText="An estimated cost for Medicaid for the household."
                                label="Medicaid Cost Estimate"
                                name="medicaidEstimate"
                                onChange={onChange(setMedicaidEstimate)}
                                placeholder="Enter Medicaid Cost Estimate"
                                value={medicaidEstimate}
                            />
                        )}
                        {isAgeBandedIchraType && (
                            <React.Fragment>
                                {!hasValue(ichraClassId) && (
                                    <React.Fragment>
                                        <Select
                                            data-cy="age-band-sizes"
                                            defaultText=""
                                            defaultValue=""
                                            errors={errors?.ageBandSize}
                                            items={AGE_BAND_SIZES}
                                            label="Age Bands"
                                            name="ageBandSize"
                                            onChange={onChange(setAgeBandSize)}
                                            optionText="text"
                                            optionValue="value"
                                            value={ageBandSize}
                                        />
                                        <Col className="p-0">
                                            <label className="mb-2">
                                                64+ Reimbursement Amounts
                                            </label>
                                            <Stack direction="row" spacing={2}>
                                                <AgeBandCoverageLevelInputs
                                                    errors={errors?.sixtyFourEe}
                                                    groupLevelAbbrev="Ee"
                                                    reimbursementRatio={eeReimbursementRatio}
                                                    setReimbursementRatio={setEeReimbursementRatio}
                                                    setSixtyFourReimbursement={setSixtyFourEe}
                                                    sixtyFourReimbursement={sixtyFourEe}
                                                />
                                                <AgeBandCoverageLevelInputs
                                                    errors={errors?.sixtyFourEs}
                                                    groupLevelAbbrev="Es"
                                                    reimbursementRatio={esReimbursementRatio}
                                                    setReimbursementRatio={setEsReimbursementRatio}
                                                    setSixtyFourReimbursement={setSixtyFourEs}
                                                    sixtyFourReimbursement={sixtyFourEs}
                                                />
                                                <AgeBandCoverageLevelInputs
                                                    errors={errors?.sixtyFourEc}
                                                    groupLevelAbbrev="Ec"
                                                    reimbursementRatio={ecReimbursementRatio}
                                                    setReimbursementRatio={setEcReimbursementRatio}
                                                    setSixtyFourReimbursement={setSixtyFourEc}
                                                    sixtyFourReimbursement={sixtyFourEc}
                                                />
                                                <AgeBandCoverageLevelInputs
                                                    errors={errors?.sixtyFourEf}
                                                    groupLevelAbbrev="Ef"
                                                    reimbursementRatio={efReimbursementRatio}
                                                    setReimbursementRatio={setEfReimbursementRatio}
                                                    setSixtyFourReimbursement={setSixtyFourEf}
                                                    sixtyFourReimbursement={sixtyFourEf}
                                                />
                                            </Stack>
                                        </Col>
                                        <CalculatedAgeBandsTable
                                            ageBand={Number(ageBandSize)}
                                            ecCoverageLevelInputs={{
                                                reimbursementRatio: Number(ecReimbursementRatio),
                                                sixtyFourReimbursement: Number(sixtyFourEc),
                                            }}
                                            eeCoverageLevelInputs={{
                                                reimbursementRatio: Number(eeReimbursementRatio),
                                                sixtyFourReimbursement: Number(sixtyFourEe),
                                            }}
                                            efCoverageLevelInputs={{
                                                reimbursementRatio: Number(efReimbursementRatio),
                                                sixtyFourReimbursement: Number(sixtyFourEf),
                                            }}
                                            esCoverageLevelInputs={{
                                                reimbursementRatio: Number(esReimbursementRatio),
                                                sixtyFourReimbursement: Number(sixtyFourEs),
                                            }}
                                        />
                                    </React.Fragment>
                                )}
                                {perMemberMedicareEstimateField}
                                {!hasValue(ichraClassId) && (
                                    <React.Fragment>
                                        <NumberTextField
                                            data-cy="dependent-kicker-threshold"
                                            errors={errors?.dependentKickerThreshold}
                                            helperText="This sets the number of dependents a household can have before the kicker will be added for each additional dependent."
                                            isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return (
                                                    !hasValue(floatValue) ||
                                                    (floatValue >= 0 &&
                                                        floatValue <=
                                                            DEPENDENT_KICKER_THRESHOLD_MAX)
                                                );
                                            }}
                                            label="Dependent Kicker Threshold"
                                            name="dependentKickerThreshold"
                                            onChange={onChange(setDependentKickerThreshold)}
                                            placeholder="Enter Dependent Kicker Threshold"
                                            value={dependentKickerThreshold}
                                        />
                                        <CurrencyTextField
                                            data-cy="additional-dependent-kicker"
                                            errors={errors?.additionalDependentKicker}
                                            helperText={getAdditionalDependentKickerHelperText(
                                                dependentKickerThreshold
                                            )}
                                            isOptional
                                            label="Additional Dependent Kicker"
                                            name="additionalDependentKicker"
                                            onChange={onChange(setAdditionalDependentKicker)}
                                            placeholder="Enter Additional Dependent Kicker"
                                            value={additionalDependentKicker}
                                        />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                        {isPlanRequired && !isIchraExchangeBenchmarkType && (
                            <React.Fragment>
                                <TextField
                                    errors={errors?.displayNameOverride}
                                    helperText="If you set this field, the text entered here will appear instead of the full plan card
                                        with benefit details when the members in this Pathway go through Member Launch."
                                    inputProps={{
                                        maxLength: DISPLAY_NAME_OVERRIDE_TEXT_MAX_LENGTH,
                                    }}
                                    isOptional
                                    label="Display Name Override"
                                    name="displayNameOverride"
                                    onChange={onChange(setDisplayNameOverride)}
                                    value={displayNameOverride}
                                />
                                <TextArea
                                    errors={errors?.extraBenefitText}
                                    helperText="If you set this field, the text entered here will display between the Plan Card/Plan Name and
                                        the Cost in the Benefits Selection modal that members in this pathway will see when they go through
                                        Member Launch."
                                    isOptional
                                    label="Extra Pathway Benefit Text"
                                    maxLength={EXTRA_PATHWAY_BENEFIT_TEXT_MAX_LENGTH}
                                    name="extraBenefitText"
                                    onChange={onChange(setExtraBenefitText)}
                                    value={extraBenefitText}
                                />
                            </React.Fragment>
                        )}
                        {isIchraExchangeBenchmarkType && <IchraRecommendedPlanInputs />}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button form="pathway-details-modal-form" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(PathwayModal);
