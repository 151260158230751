import Grid from '@mui/material/Grid';
import { Flows, Tasks } from 'api/generated/enums';
import { IUserFlowDto, TaskDto } from 'api/generated/models';
import { IIchraEnrollmentTaskFlowContent } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import AdvisementNeededPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/AdvisementNeededPage';
import AgreeToMemberTermsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/AgreeToMemberTermsPage';
import ChooseYourPlanPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/ChooseYourPlanPage';
import EnterHouseholdInfoPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/EnterHouseholdInfoPage';
import HealthConditionsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/HealthConditionsPage';
import HouseholdSummaryPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/HouseholdSummaryPage';
import PersonalInfoPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/PersonalInfoPage';
import PreferredProvidersPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/PreferredProvidersPage';
import PrescriptionsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/PrescriptionsPage';
import PrimaryAddressPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/PrimaryAddressPage';
import SelectAncillaryBenefitsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/SelectAncillaryBenefitsPage';
import WelcomeToRemodelHealthPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/WelcomeToRemodelHealthPage';
import YourJobPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/YourJobPage';
import YourReimbursementAmountPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/YourReimbursementAmountPage';
import HouseholdMembersPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/householdMembersPage/HouseholdMembersPage';
import SocialSecurityNumberPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/socialSecurityNumbersPage/SocialSecurityNumberPage';
import SubmitBenefitSelectionsPage from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/SubmitBenefitSelectionsPage';
import React from 'react';
import { hot } from 'react-hot-loader';
import WelcomeToRenewalRemodelHealthPage
    from 'pages/dashboard/ichraTaskFlow/taskFlowPages/WelcomeToRenewalRemodelHealthPage';

const handleCurrentFlowContent = (currentTask: TaskDto, currentFlow?: IUserFlowDto) => {
    switch (currentTask?.taskId) {
        case Tasks.WelcomeToRemodelHealth:
             if(currentFlow?.flowId === Flows.IchraLaunch){
                 return <WelcomeToRemodelHealthPage currentTask={currentTask} />;
             } else{
                return  <WelcomeToRenewalRemodelHealthPage currentTask={currentTask} />;
             }
        case Tasks.EnterHouseholdInfo:
            return <EnterHouseholdInfoPage currentFlow={currentFlow} currentTask={currentTask} />;
        case Tasks.PersonalInfo:
            return <PersonalInfoPage currentTask={currentTask} />;
        case Tasks.YourJob:
            return <YourJobPage currentTask={currentTask} />;
        case Tasks.PrimaryAddressInfo:
            return <PrimaryAddressPage currentTask={currentTask} />;
        case Tasks.HouseholdMembers:
            return <HouseholdMembersPage currentTask={currentTask} />;
        case Tasks.PreferredProviders:
            return <PreferredProvidersPage currentTask={currentTask} />;
        case Tasks.Prescriptions:
            return <PrescriptionsPage currentTask={currentTask} />;
        case Tasks.CurrentHealthConditions:
            return <HealthConditionsPage currentTask={currentTask} />;
        case Tasks.SocialSecurityNumbers:
            return <SocialSecurityNumberPage currentTask={currentTask} />;
        case Tasks.HouseholdSummary:
            return <HouseholdSummaryPage />;
        case Tasks.YourReimbursementAmount:
            return <YourReimbursementAmountPage currentTask={currentTask} />;
        case Tasks.Advisement:
        case Tasks.ChangeAdvisement:
            return <AdvisementNeededPage currentTask={currentTask} />;
        case Tasks.ChooseYourPlan:
            return <ChooseYourPlanPage currentTask={currentTask} />;
        case Tasks.SelectAncillaryBenefits:
            return <SelectAncillaryBenefitsPage currentTask={currentTask} />;
        case Tasks.MemberTerms:
            return (
                <AgreeToMemberTermsPage
                    currentTask={currentTask}
                    flowId={currentFlow?.flowId as Flows}
                />
            );
        case Tasks.SubmitBenefitSelections:
            return <SubmitBenefitSelectionsPage currentTask={currentTask} />;
        default:
            return <React.Fragment />;
    }
};

const IchraEnrollmentTaskFlowContent = ({
    currentFlow,
    currentTask,
}: IIchraEnrollmentTaskFlowContent) => {
    const currentTaskContent = handleCurrentFlowContent(currentTask, currentFlow);

    return (
        <Grid alignItems="center" container direction="column" justifyContent="center" width="100%">
            {currentTaskContent}
        </Grid>
    );
};

export default hot(module)(IchraEnrollmentTaskFlowContent);
