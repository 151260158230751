import { Divider, Grid, Typography } from '@mui/material';
import {
    GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
    getSelectedPlansForRenewal,
} from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { completeTask } from 'actions/taskFlows/completeTask';
import { IMarketplacePlanDto, PlanInfoDto, TaskDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { convertToPlanInfo, MAX_WIDTH } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import {
    getHeader,
    getIsIndividualOnly,
} from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/SubmitBenefitSelectionsPage';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { arrayHasValue, hasValue } from 'utilities/index';

const MAX_CARD_WIDTH = '400px';

const MedicareOnlyPage = ({ currentTask }: { currentTask: TaskDto }) => {
    const dispatch = useThunkDispatch();
    const { activeWageUp, userId, user } = useUserProps();
    const { hasNoAncillaryBenefits, isLoadingPlan, plans } = useSelector((state: AppStore) => ({
        hasNoAncillaryBenefits: !arrayHasValue(state.ancillaryBenefits),
        isLoadingPlan: hasApiActivity(state, GET_SELECTED_PLANS_FOR_RENEWAL_ACTION),
        plans: state.selectedPlansForRenewal,
    }));

    const handleSubmit = async () => {
        const response = {
            isWaived: false,
            planInfoDtos: plans.effectiveMajorMedicalSelectedPlans?.map((plan) =>
                convertToPlanInfo(
                    plan.householdMembersCovered,
                    plan.isPrimaryCovered,
                    undefined,
                    plan,
                    plan.year
                )
            ) as PlanInfoDto[],
        };

        await dispatch(completeTask(currentTask.globalId, response));
    };

    useEffect(() => {
        const year = user?.activeDate?.getYear();
        if (hasValue(year)) {
            dispatch(getSelectedPlansForRenewal(userId, year));
        }
    }, [dispatch, user?.activeDate, userId]);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            item
            maxWidth={MAX_WIDTH}
            rowGap={2}
            width="100%"
        >
            <Grid item xs={12}>
                <IchraFlowTitle title="Continue with your Medicare benefit(s)" />
            </Grid>
            <Grid
                container
                direction="column"
                item
                justifyContent="space-between"
                spacing={4}
                xs={12}
            >
                <Skeleton count={2} height={440} isEnabled={isLoadingPlan} sx={{ mt: 10 }}>
                    <Grid item marginRight={4} maxWidth={MAX_CARD_WIDTH}>
                        {plans.effectiveMajorMedicalSelectedPlans?.map((plan) => (
                            <MarketplacePlanCard
                                hideCost
                                hidePlanCardContent
                                hideSelectPlan
                                householdMembersCovered={plan?.householdMembersCovered}
                                individualOnly={getIsIndividualOnly(
                                    convertToPlanInfo(
                                        plan.householdMembersCovered,
                                        plan.isPrimaryCovered,
                                        undefined,
                                        plan,
                                        plan.year
                                    ) as PlanInfoDto
                                )}
                                isPrimaryCovered={plan?.isPrimaryCovered}
                                key={`${plan?.planId}-${plan?.planTypeId}`}
                                pathwayPlanCardHeader={getHeader(
                                    convertToPlanInfo(
                                        plan.householdMembersCovered,
                                        plan.isPrimaryCovered,
                                        undefined,
                                        plan,
                                        plan.year
                                    ) as PlanInfoDto,
                                    activeWageUp?.reimbursement ?? 0,
                                    false,
                                    true
                                )}
                                plan={
                                    {
                                        ...(plan as Partial<IMarketplacePlanDto>),
                                        isMarketplace: true,
                                    } as IShoppingPlan
                                }
                            />
                        ))}
                    </Grid>
                    <Grid container direction="column" item rowGap={2}>
                        <Typography variant="body1">
                            You are currently on Medicare. If you would like to change anything
                            about your Medicare benefit, please email{' '}
                            <a href="mailto:medicare@remodelhealth.com">
                                medicare@remodelhealth.com
                            </a>{' '}
                            or schedule an advisement.
                        </Typography>
                        <Typography variant="body1">
                            If you&apos;d like to continue receiving your maximum monthly
                            reimbursement amount of{' '}
                            {formatCurrency(activeWageUp?.reimbursement ?? 0, {
                                emptyIfNaN: true,
                                preserveDecimal: true,
                            })}{' '}
                            from your employer for {user?.activeDate?.getYear()}, please click
                            &quot;Continue&quot;.
                        </Typography>
                        <Typography variant="body1">
                            If you&apos;d like to waive the reimbursement offered by your employer,
                            click &quot;Waive ICHRA&quot;.
                        </Typography>
                    </Grid>
                </Skeleton>
            </Grid>
            <Grid
                container
                direction="row"
                item
                justifyContent="space-evenly"
                marginTop={2}
                xs={12}
            >
                <Grid item mb={2} xs={12}>
                    <Divider />
                </Grid>
                <Grid alignItems="center" direction="row" gap={2} marginBottom={6}>
                    <IchraFlowButtons
                        currentTaskId={currentTask.globalId}
                        displayAsRow
                        handleNext={handleSubmit}
                        isLoading={isLoadingPlan}
                        redirectToDashboard={hasNoAncillaryBenefits}
                        showWaiveButton
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default hot(module)(MedicareOnlyPage);
